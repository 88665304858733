@use 'variables' as *
@use 'CustomButton'
@use 'Tab'
@use 'Tag'
@use 'helpers'
@use 'AsyncSelect'
/* Global styles */

*
  font-family: 'Open Sans', sans-serif
  box-sizing: border-box

html
  width: 100%
  height: 100dvh
  padding: 0
  margin: 0

body
  position: relative
  background-color: $darkBackgroundColor
  overflow: auto
  width: 100%
  height: 100%
  padding: 0
  margin: 0 auto
  font-size: 12px
  color: $fontColor
  -webkit-font-smoothing: antialiased
  -moz-font-smoothing: antialiased
  -ms-font-smoothing: antialiased
  -o-font-smoothing: antialiased

::-ms-clear
  display: none

#app
  width: 100%
  height: 100%

select:invalid
  color: $placeholderColor !important

input,
textarea
  -webkit-appearance: none

input[type="checkbox"]
  -webkit-appearance: checkbox
  margin-right: 7px

textarea
  resize: none

textarea::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder
  color: $placeholderColor !important

textarea:-moz-placeholder,
input[type="text"]:-moz-placeholder,
input[type="number"]:-moz-placeholder
  color: $placeholderColor !important

textarea::-moz-placeholder,
input[type="text"]::-moz-placeholder,
input[type="number"]::-moz-placeholder
  color: $placeholderColor !important

textarea:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder
  color: $placeholderColor !important

textarea::placeholder,
input[type="text"]::placeholder,
input[type="number"]::placeholder
  color: $placeholderColor !important

.input-field.has-error
  border-color: $redColor
  border-width: 2px

body.no-scroll
  overflow: hidden

#body
  overflow-x: hidden

.search-results-container
  max-width: 1536px
  width: 100%
  padding-right: 15px
  padding-left: 15px

  @media (min-width: 1537px)
    max-width: 100vw

  @media (min-width: 1630px)
    max-width: 1630px
    margin-right: auto
    margin-left: auto

.clearfix::after
  content: ' '
  display: block
  clear: both

a
  color: $fontColor
  transition: color 100ms linear

a:hover
  color: $fontColor

h3
  font-size: 24px

strong
  font-weight: 700

.hidden
  display: none

.noselect
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.hr
  border-bottom: 1px solid $lightBorderColor
  margin: 10px 0
  width: 100%

.hr.tall
  margin: 30px 0

.text-left
  text-align: left

.text-center
  text-align: center

.text-right
  text-align: right

.pull-left
  float: left

.pull-right
  float: right

.without-padding
  padding: 0 !important

.noselect
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.uppercase
  text-transform: uppercase

table
  position: relative
  width: 100%
  table-layout: fixed

table thead tr th
  text-align: left

.red-text
  color: $redColor

.blue-text
  color: $blueColor

.green-text
  color: $greenColor

.orange-text
  color: $orangeColor

[data-text-size=medium]
  font-size: 16px

[data-text-size=large]
  font-size: 18px

[data-text-size=huge]
  font-size: 24px

[data-text-color=green]
  color: $greenColor

[data-text-color=blue]
  color: $blueColor

[data-text-color=red]
  color: $redColor

[data-text-color=orange]
  color: $orangeColor

.vertical-center
  display: flex
  flex-direction: column
  justify-content: center

.vertical-bottom
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: flex-end

embed
  display: block

.no-padding
  padding: 0 !important

.no-margin
  margin: 0 !important

/* Icon */

.icon img
  height: 22px
  vertical-align: -5px
  margin-right: 8px

.icon span + span
  margin-left: 10px

.address-field-icon
  height: 18px
  width: 18px
  margin: 0
  position: absolute
  top: 50%
  transform: translateY(-50%)
  right: 0
  cursor: pointer

.minus-field-icon
  height: 18px
  width: 18px
  margin: 0
  position: absolute
  top: 8px
  right: 0
  cursor: pointer

.address-field > div:first-of-type
  width: calc(100% - 30px)
  float: left

.address-field > div:first-of-type.editable
  width: calc(100% - 60px)

.address-field .address-field-edit,
.address-field .address-field-add
  position: relative
  display: none
  width: 30px
  height: 30px
  float: right

.address-field .address-field-add
  position: relative
  display: block
  width: 30px
  height: 30px
  float: right

.editable + .address-field-edit
  display: block

.modal-content .address-edit-form .input-wrapper input
  height: 42px
  padding-left: 10px !important

.action-icon
  height: 20px
  width: 20px
  cursor: pointer
  display: block

/* Labels */

.label
  display: inline-block
  padding: 1px 10px 2px 10px
  background-color: $darkBackgroundColor
  color: $fontColor
  border-radius: 5px
  font-size: 10px
  text-transform: uppercase
  font-weight: bold
  line-height: 1.8
  background: $borderColor no-repeat center center
  background-size: 14px auto

.label[data-color=green]
  background-color: $greenColor
  color: white

.label[data-color=blue]
  background-color: $blueColor
  color: white

.label[data-color=red]
  background-color: $redColor
  color: white

.label[data-color=orange]
  background-color: $orangeColor
  color: white

.label[data-color=black]
  background-color: $blackColor
  color: white

.label.check
  content: ' '
  width: 21px
  height: 21px
  background-image: url(../resources/img/icons/check-white.svg)
  background-size: 12px auto
  border-radius: 100%

.label.circle
  content: ' '
  width: 15px
  height: 15px
  padding: 0
  border-radius: 100%
  vertical-align: middle

.label.circle.large
  width: 22px
  height: 22px
  text-align: center

.label.circle.large span
  display: inline-block
  margin-top: -1px
  font-size: 13px
  font-weight: bold
  color: white

.label.circle[data-color=green]
  background-color: $lightGreenColor

.label.circle[data-color=yellow]
  background-color: $yellowColor

.label.circle[data-color=orange]
  background-color: $orangeColor

.label.circle[data-color=blue]
  background-color: $blueColor

.label.circle[data-color=red]
  background-color: $redColor

.label.circle[data-color=gray]
  background-color: $placeholderColor

.label[data-type=outline]
  background: transparent
  border: 1px solid $borderColor
  color: $placeholderColor

.label[data-type=active]
  background-color: $lightGreenColor
  color: white

/* Table icons */

.table-icon
  display: inline-block
  width: 24px
  height: 24px
  background: no-repeat center center
  background-size: 18px auto
  margin-right: 3px

.table-icon[data-icon="arrow-up-red"]
  background-image: url(../resources/img/icons/icon-arrow-up-red.svg)

.table-icon[data-icon="arrow-up-yellow"]
  background-image: url(../resources/img/icons/icon-arrow-up-yellow.svg)

.table-icon[data-icon="u-red"]
  background-image: url(../resources/img/icons/icon-u-red.svg)

.table-icon[data-icon="u-red-crossed"]
  background-image: url(../resources/img/icons/icon-u-red-crossed.svg)

.table-icon[data-icon="i"]
  background-image: url(../resources/img/icons/icon-i.svg)

.table-icon[data-icon="i-crossed"]
  background-image: url(../resources/img/icons/icon-i-crossed.svg)

.table-icon[data-icon="s-red"]
  background-image: url(../resources/img/icons/icon-s-red.svg)

.table-icon[data-icon="s-black"]
  background-image: url(../resources/img/icons/icon-s-black.svg)

.table-icon[data-icon="o-red"]
  background-image: url(../resources/img/icons/icon-o-red.svg)

.table-icon[data-icon="warning-yellow"]
  background-image: url(../resources/img/icons/icon-warning-yellow.svg)

.table-icon[data-icon="car-red"]
  background-image: url(../resources/img/icons/icon-car-red.svg)

.table-icon[data-icon="bin"]
  background-image: url(../resources/img/icons/icon-bin.svg)

.table-icon[data-size="large"]
  background-size: 21px auto

/* Data table */

.responsive-table-wrapper
  overflow: auto
  height: 100%

table.responsive-table
  width: 100%
  border-collapse: separate
  table-layout: auto
  border-spacing: 0

.content-table tr th.sortable
  cursor: pointer

.content-table tr th.sortable:hover
  background-color: $alternateBackgroundColor

.content-table tr th.sortable .sort-icon
  display: inline-block
  content: ' '
  width: 10px
  height: 15px
  background: url('../resources/img/icons/sort-default.svg') no-repeat center center
  background-size: contain
  vertical-align: middle
  margin-left: 10px

.content-table tr th.sortable.asc .sort-icon
  background-image: url('../resources/img/icons/sort-asc.svg')

.content-table tr th.sortable.desc .sort-icon
  background-image: url('../resources/img/icons/sort-desc.svg')

.action-btns-col
  position: sticky
  right: 0
  visibility: hidden

.action-btns-box
  background: linear-gradient(270deg, #F6FBFE 90.13%, rgba(246, 251, 254, 0) 100%)
  height: 100%
  position: absolute
  right: 0
  top: 0
  visibility: hidden
  opacity: 0

.content-table
  & tbody tr:hover
   background-color:  #1D96EE0A

.content-table
  & tbody tr:hover .action-btns-box
    visibility: visible
    opacity: 1
/* Alerts */

.alert
  display: block
  width: 100%
  padding: 20px
  font-size: 14px
  color: $placeholderColor
  background: $alternateBackgroundColor
  border-radius: 5px
  text-align: center
  font-weight: bold
  margin: 20px 0
  z-index: 120

.alert[data-color=blue]
  background-color: $blueColor
  color: white

.alert[data-color=green]
  background-color: $greenColor
  color: white

.alert[data-color=red]
  background-color: $redColor
  color: white

.alert[data-color=orange]
  background-color: $orangeColor
  color: white

/* Info banners */

.info-banner
  display: block
  width: 100%
  padding: 15px 20px
  font-size: 13px
  color: $placeholderColor
  background: $alternateBackgroundColor
  border-radius: 5px
  text-align: center
  font-weight: bold
  margin: 20px 0

.info-banner[data-color=green]
  background-color: #D6EBD5
  color: $darkFontColor

/* Toast message */

.toast-message
  position: fixed
  display: flex
  padding-left: 40px
  padding-right: 40px
  align-items: center
  justify-content: space-between
  bottom: 60px
  border-radius: 7px
  overflow: hidden
  left: 0
  right: 0
  margin: 0 auto
  max-width: 1200px
  width: 90%
  border-top: 5px solid white
  background: $lightBackgroundColor
  z-index: 10
  height: 80px
  transform: translateY(-100%)
  box-shadow: none
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1)

.toast-message.shown
  transform: translateY(0)
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15)

.toast-message .message-text
  font-size: 14px
  font-weight: 700
  max-width: 80%

.toast-message .button
  margin-top: 0
  margin-left: 20px

.toast-message[data-color=red]
  border-top-color: $redColor
  color: $redColor

.toast-message[data-color=blue]
  border-top-color: $blueColor
  color: $blueColor

.toast-message[data-color=green]
  border-top-color: $greenColor
  color: $greenColor

.toast-message[data-color=orange]
  border-top-color: $orangeColor
  color: $orangeColor

.toast-message[data-color=yellow]
  border-top-color: $yellowColor
  color: $yellowColor

/* Modal */

.modal
  position: fixed
  padding: 0
  transition: all 200ms linear
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  overflow: auto
  text-align: center
  opacity: 0
  visibility: hidden
  z-index: 200
  white-space: nowrap

.modal.shown
  opacity: 1
  visibility: visible

.modal:after
  display: inline-block
  height: 100%
  margin-left: -0.05em
  content: ' '
  vertical-align: middle

.modal:before
  position: fixed
  content: ' '
  top: 0
  left: 0
  bottom: 0
  right: 0
  width: 100%
  height: 100%
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)
  transition: all 200ms linear
  z-index: 10

.modal-wrapper
  position: relative
  display: inline-block
  vertical-align: middle
  z-index: 10
  opacity: 0
  visibility: hidden
  margin: 20px
  background: $lightBackgroundColor
  border-radius: 4px
  width: calc(100% - 40px)
  max-width: 1400px
  text-align: left
  transform: scale(0.7)
  transition: opacity 200ms linear, visibility 200ms linear, transform 200ms linear
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.25)

.modal.shown .modal-wrapper
  opacity: 1
  visibility: visible
  transform: scale(1)

.modal[data-size="xs"] .modal-wrapper
  max-width: 400px

.modal[data-size="s"] .modal-wrapper
  max-width: 500px

.modal[data-size="m"] .modal-wrapper
  max-width: 720px

.modal[data-size="l"] .modal-wrapper
  max-width: 900px

.modal-header
  padding: 40px 40px 0 40px

.modal-header h3
  font-size: 18px
  margin: 0

.modal-content
  padding: 40px 40px 40px 40px
  white-space: normal
  font-size: 14px
  line-height: 1.7

.modal-footer
  padding: 20px 40px
  text-align: right
  border-top: 1px solid $borderColor
  box-shadow: 0 12px 34px 0 rgba(174, 174, 174, 0.45)

.modal-footer .button
  margin-left: 15px

.modal-wrapper .close-button
  position: absolute
  top: -15px
  right: -15px
  width: 36px
  height: 36px
  opacity: 1
  background: $redColor
  color: white
  font-weight: 400
  text-align: center
  font-size: 16px
  border-radius: 100%
  padding-top: 8px
  cursor: pointer
  z-index: 2
  font-weight: bold
  transition: all 100ms linear

.close-button:hover
  transform: scale(1.1)
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.10)

/* Empty state */

.empty-state
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: white no-repeat center calc(50% - 40px)
  background-size: auto 80px
  font-size: 13px
  color: $placeholderColor
  font-weight: bold
  text-align: center

.empty-state:after
  position: absolute
  top: calc(50% + 40px)
  left: 0
  right: 0
  width: 100%
  max-width: 300px
  margin: auto
  left: 0
  content: attr(data-text)
  text-align: center
  padding: 0 20px

/* Layouts */

.simple-layout
  height: 100%
  background: $lightBackgroundColor
  overflow: auto

[data-page=search] .simple-layout
  height: calc(100% - 60px)

.main-layout
  height: calc(100% - 200px)
  position: relative

/* Header */

header.top-header
  background: $lightBackgroundColor
  border-bottom: 1px solid $borderColor
  height: 60px
  padding: 0 40px
  display: flex
  align-items: center

header.top-header .button
  margin-top: 0

.top-header-divider
  margin-left: auto

header.top-header .logo img
  height: 40px

header.top-header .header-search
  margin-left: 40px

header.top-header .header-search a
  font-size: 14px
  font-weight: bold
  text-decoration: none
  color: $darkFontColor

header.top-header .ext-integrations
  display: flex
  align-items: center
  gap: 10px
  list-style: none
  padding: 0
  margin: 0
  margin-left: 40px

header.top-header .ext-integrations .ext-integrations-badge
  display: flex
  align-items: center
  gap: 10px
  border-radius: 40px
  padding: 4px 12px

.ext-integrations-badge.liveagent-badge
  background: linear-gradient(#F9B567, #F6921E)
  color: $whiteColor

.ext-integrations-badge.finesse-badge
  background: linear-gradient(#677EF9, #1E42F6)
  color: $whiteColor

.ext-integrations-badge.call250-badge
  background: linear-gradient(#F96767, #F61E1E)
  color: $whiteColor

.ext-integrations-badge.liveagent-badge .ext-integrations-icon
  width: 30px
  height: 24px
  background-image: url(../resources/img/icons/liveagent.svg)

.ext-integrations-badge.finesse-badge .ext-integrations-icon
  width: 24px
  height: 24px
  background-image: url(../resources/img/icons/phone-call.svg)

.ext-integrations-badge.call250-badge .ext-integrations-icon
  width: 24px
  height: 24px
  background-image: url(../resources/img/icons/user-left.svg)

header.top-header .ext-integrations .ext-integrations-title
  font-size: 14px
  font-weight: 700

header.top-header .ext-integrations .ext-integrations-id
  font-size: 12px

header.top-header .ext-integrations .ext-integrations-close
  width: 16px
  height: 16px
  background: none
  padding: 0
  margin: 0
  border: none
  background-image: url(../resources/img/icons/close.svg)
  cursor: pointer

header.top-header .header-profile
  position: relative
  margin-left: 40px

header.top-header .header-profile strong
  font-size: 14px
  display: block

header.top-header .header-profile span
  font-size: 10px
  display: block
  color: $placeholderColor
  font-weight: bold

header.top-header .header-profile .profile-photo
  display: inline-block
  width: 45px
  height: 45px
  margin-top: 7px
  border-radius: 100%
  background: no-repeat center center
  background-size: cover

header.top-header .header-profile .profile-content
  display: inline-block
  vertical-align: 10px
  padding: 0 15px
  margin-top: 12px

header.top-header .menu-toggle
  margin-left: 20px
  width: 40px
  height: 40px
  position: relative

header.top-header .menu-toggle > span
  content: ' '
  display: block
  height: 40px
  background: url(../resources/img/icons/menu-toggle.svg) no-repeat center center
  background-size: 26px auto
  cursor: pointer

/* Header refactor */
.page-subheader
  display: flex
  align-items: center
  gap: 20px
  padding: 10px 20px
  background: white
  border-bottom: 1px solid $borderColor
  box-shadow: 4px 2px 10px 0 rgba(218, 218, 218, 0.54)
  transition: opacity 400ms ease-in-out, box-shadow 100ms ease-in-out
  opacity: 0
  animation: fadeIn
  animation-duration: 400ms
  animation-delay: 200ms
  animation-fill-mode: forwards
  z-index: 6

.page-subheader .button
  margin: 0

.page-subheader .subheader-title
  font-weight: bold

/* sub-header */

header.sub-header
  background: #3F4159
  color: white
  padding: 20px 40px 20px 40px
  display: flex
  gap: 40px
  height: 140px // TODO: CP-3417 - remove scroll bar on tablet (hotfix)
  overflow: hidden // TODO: CP-3417 - remove scroll bar on tablet (hotfix)

header.sub-header.testing
  background: #034F2F

header.sub-header label
  color: #9B9B9B
  font-size: 12px
  text-transform: uppercase
  font-weight: bold

header.sub-header h4
  margin: 0
  font-size: 18px
  font-weight: bold

header.sub-header .subheader-flex-section
  display: flex
  align-items: center
  gap: 10px

header.sub-header .subheader-flex-section.with-margin
  margin-bottom: 20px

header.sub-header .inline-label
  width: 50px

header.sub-header .column.right
  margin-left: auto

header.sub-header > div:first-of-type
  border-right: 1px solid rgba(255, 255, 255, 0.2)
  width: 180px

header.sub-header div
  font-size: 14px
  font-weight: bold

header.sub-header .value
  border-bottom: 1px solid transparent

header.sub-header a
  color: white
  text-decoration: none

header.sub-header .value.empty,
header.sub-header .value.red
  border-bottom-color: $redColor
  background: url(../resources/img/icons/alert.svg) no-repeat right center
  background-size: 13px auto
  padding-right: 20px

header.sub-header .inline .value
  display: inline
  margin-left: 10px

header.sub-header .value.amber
  border-bottom-color: $amberColor
  background: url(../resources/img/icons/alert-amber.svg) no-repeat right center
  background-size: 13px auto
  padding-right: 20px

header.sub-header .square-label
  font-size: 16px
  font-weight: bold
  text-align: center
  padding: 4px 10px
  border: 2px solid transparent
  border-radius: 7px
  margin-top: 20px
  display: inline-block

header.sub-header .square-label.green
  border-color: $greenColor

header.sub-header .square-label.blue
  border-color: $blueColor

header.sub-header .square-label.red
  border-color: $redColor
  background-color: $redColor

header.sub-header .square-label + .square-label
  margin-left: 15px

.header-icon-container
  opacity: 0
  height: 18px
  align-self: end
  transition: opacity 150ms linear

.subheader-flex-section:hover .header-icon-container,
.header-icon-container:hover
  opacity: 0.7

.header-field-icon
  width: 18px
  height: 18px
  cursor: pointer

.header-field-icon.copy
  background-image: url(../resources/img/icons/copy.svg)
  float: right

.header-field-icon.edit
  background-image: url(../resources/img/icons/pencil.svg)
  right: 24px
  background-size: 18px auto
  float: right

.header-field-icon.verification
  display: inline-block
  width: 16px
  height: 16px
  background-size: 16px auto
  background-repeat: no-repeat
  background-image: url(../resources/img/icons/verifying-check-default.svg)

  &.disabled
    cursor: not-allowed
    background-image: url(../resources/img/icons/verifying-check-default-disabled.svg)
  &.checked
    background-image: url(../resources/img/icons/verifying-check-selected.svg)
  &.checked.testing
    background-image: url(../resources/img/icons/verifying-check-selected-testing.svg)


.header-field-icon.pin
  width: 25px
  height: 25px
  display: inline-block
  background-size: 25px auto

  &.pin-locked
    background-image: url(../resources/img/icons/pin/pin-locked.svg)

  &.pin-unlocked
    background-image: url(../resources/img/icons/pin/pin-unlocked.svg)

  &.pin-waiting
    background-image: url(../resources/img/icons/pin/pin-waiting.svg)

  &.pin-phone
    background-image: url(../resources/img/icons/pin/pin-phone.svg)

  &.pin-mail
    background-image: url(../resources/img/icons/pin/pin-mail.svg)

.methodology-icon
  background-image: url(../resources/img/icons/methodology.svg)
  background-size: 16px auto
  float: left
  width: 16px
  height: 16px
  cursor: pointer
  opacity: 0.7
  margin-top: 22px
  margin-left: 10px

.methodology-icon:hover
  opacity: 1

.guideline-icon
  background-image: url(../resources/img/icons/question.svg)
  background-size: 16px auto
  width: 16px
  height: 16px
  cursor: pointer
  opacity: 0.7

.guideline-icon:hover
  opacity: 1

header.sub-header .regulacie-container
  display: flex
  align-items: center
  gap: 10px
  margin-top: 5px

header.sub-header .regulacie-box
  display: inline-block
  text-align: center
  font-size: 16px
  font-weight: bold
  padding: 4px 10px
  border: 2px solid $blueColor
  border-radius: 7px

/* Digiscore - Heatmapa */

.digi-score
  width: 12px
  height: 14px
  background-size: 10px 14px
  background-position: right
  background-repeat: no-repeat
  display: inline-block

  &.n0
    background-image: url(../resources/img/icons/numbers/0.svg)

  &.n1
    background-image: url(../resources/img/icons/numbers/1.svg)

  &.n1_thin
    background-image: url(../resources/img/icons/numbers/1_thin.svg)
    width: 6px !important
    background-size: 2px 14px !important

  &.n2
    background-image: url(../resources/img/icons/numbers/2.svg)

  &.n3
    background-image: url(../resources/img/icons/numbers/3.svg)

  &.n4
    background-image: url(../resources/img/icons/numbers/4.svg)

  &.n5
    background-image: url(../resources/img/icons/numbers/5.svg)

  &.n6
    background-image: url(../resources/img/icons/numbers/6.svg)

  &.n7
    background-image: url(../resources/img/icons/numbers/7.svg)

  &.n8
    background-image: url(../resources/img/icons/numbers/8.svg)

  &.n9
    background-image: url(../resources/img/icons/numbers/9.svg)

  &.np
    background-image: url(../resources/img/icons/numbers/percent.svg)

/* Box */

.box
  background: white
  border-radius: 8px
  border: 1px solid #10234A26
  width: 100%
  margin: 0 auto

.box:last-of-type
  margin-bottom: 20px

.box:not(.dashboard-box)
  opacity: 1
  animation: none

.box + .box
  margin-top: 20px

.box.login-box
  padding: 40px
  max-width: 400px
  margin-top: 15vh
  margin-bottom: 100px
  box-shadow: 0 7px 35px 0 rgba(0, 0, 0, 0.07)
  border: none

.box.login-box .logo-wrapper
  margin-bottom: 40px
  text-align: center

.box.login-box .logo-wrapper img
  width: 140px

.box.login-box .checkbox-wrapper
  margin-top: 20px

.box .box-header
  background: $alternateBackgroundColor
  font-size: 14px
  font-weight: bold
  padding: 14px 15px
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px
  border-top-left-radius: 8px
  border-top-right-radius: 8px
  border-bottom: 1px solid $borderColor

.box .box-content + .box-header
  border-top-left-radius: 0
  border-top-right-radius: 0
  border-bottom-left-radius: 5px
  border-bottom-right-radius: 5px

.box .box-header + .box-header
  border-top-left-radius: 0
  border-top-right-radius: 0

.box .box-header[data-icon]
  padding-left: 60px
  background: no-repeat left 20px center
  background-size: auto 25px

.box .box-header[data-icon=info]
  background-image: url(../resources/img/icons/header-info.svg)

.box .box-header[data-icon=error]
  background-image: url(../resources/img/icons/header-error.svg)

.box .box-header[data-color=orange]
  background-color: $orangeColor
  color: white

.box .box-header[data-color=red]
  background-color: $redColor
  color: white

[data-page=dashboard] .box,
.dashboard-box
  margin-bottom: 20px
  opacity: 1
  transition: all 5ms linear
  z-index: 10

.dashboard-box .box-header
  position: relative
  background-color: $headerBackgroundColor
  padding-top: 10px
  padding-bottom: 10px

.dashboard-box .box-header .box-header-handle
  position: absolute
  content: ' '
  left: 20px
  top: 10px
  width: 20px
  height: 20px
  background: url(../resources/img/icons/handle.svg) no-repeat center center
  background-size: 16px auto
  opacity: 0
  transition: all 150ms linear
  cursor: move

.dashboard-box:hover .box-header .box-header-handle
  opacity: 0.3

.dashboard-box .box-header .box-header-handle:hover
  opacity: 0.6
  transform: scale(1.1)

.dashboard-box .box-header .box-header-settings
  position: absolute
  content: ' '
  right: 20px
  top: 10px
  width: 20px
  height: 20px
  background: url(../resources/img/icons/settings.svg) no-repeat center center
  background-size: 16px auto
  cursor: pointer
  opacity: 0
  transition: all 150ms linear

.dashboard-box:hover .box-header .box-header-settings
  opacity: 0.3

.dashboard-box .box-header .box-header-settings:hover
  opacity: 0.6
  transform: scale(1.1)

.widget-box
  position: relative
  border: 1px solid $borderColor
  border-radius: 7px
  height: calc(100% - 20px)
  margin-bottom: 20px
  margin-top: 2px
  display: flex
  flex-direction: column

.widget-box-header
  position: relative
  background: $alternateBackgroundColor
  border-top-left-radius: 7px
  border-top-right-radius: 7px
  font-size: 12px
  font-weight: bold
  text-align: left
  padding: 4px 10px
  height: 30px

.widget-box-header.active:after
  position: absolute
  content: ' '
  top: -7px
  right: -7px
  width: 20px
  height: 20px
  border-radius: 50%
  background: $lightGreenColor url(../resources/img/icons/check-white.svg) no-repeat center center
  background-size: 10px auto

.widget-box-content
  padding: 20px 10px
  font-size: 12px
  height: calc(100% - 70px)

.widget-box-content ul
  list-style-type: bullet
  margin: 0
  padding: 0
  margin-left: 25px

.widget-box-content ul li
  margin: 0
  padding: 0

.widget-box-footer
  position: relative
  padding: 0 10px 10px 10px
  color: $placeholderColor
  font-size: 12px
  font-weight: bold
  height: 50px
  text-align: center

.widget-box-footer .label
  float: none
  margin-top: 7px

.widget-box-overlay
  position: absolute
  top: 0
  left: 0
  width: calc(100% + 2px)
  display: flex
  align-items: center
  justify-content: center
  border-radius: 7px
  opacity: 0
  visibility: hidden
  transform: scale(0.8)
  transition: all 300ms ease-in-out
  border: none

.widget-box:hover .widget-box-overlay
  opacity: 1
  visibility: visible
  transform: scale(1)

.widget-box-overlay .button
  font-size: 11px
  text-transform: uppercase
  padding-left: 15px
  padding-right: 15px
  margin-top: 0px

.box-header-dropdown .dropdown
  top: calc(100% + 5px)
  right: 10px
  width: 250px

.box-header-dropdown .dropdown .disabled
  background-color: $alternateBackgroundColor

.box-header-dropdown .dropdown .disabled a
  color: rgb(155, 155, 155)

.box .box-header [class*="col-"]
  margin-bottom: 0

.dashboard-box .box-header input
  background: transparent
  border: none
  text-align: center
  font-size: 12px
  font-weight: bold
  width: calc(100% - 60px)
  border-bottom: 1px solid transparent

.dashboard-box .box-header input:invalid
  border-bottom: 1px solid red

.box .box-header .button
  margin-top: 0

.box .box-content
  position: relative
  padding: 15px 20px

.box-content::after
  display: initial
  content: ''

.box .box-footer
  padding: 10px 20px
  font-size: 11px
  color: $placeholderColor
  font-weight: 700
  text-align: right
  border-top: 1px solid $borderColor
  height: 36px

.box .box-footer strong
  color: $darkFontColor
  font-weight: bold

.dashboard-box .box-content
  height: 265px
  overflow: auto

.dashboard-box .box[data-type=no-footer] .box-content
  height: 296px

[data-page=dashboard] .main-content .content-wrapper
  padding: 10px

.dashboard-box,
[data-page=dashboard] .box
  float: left
  width: calc(33.3333% - 20px)
  margin: 10px

.dashboard-box + .dashboard-box
  margin: 10px

/* OAuth Login */

.login-content
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: 100%
  min-height: 300px

.login-content .logo-wrapper
  margin-bottom: 40px

.login-content .logo-wrapper img
  width: 200px
  height: 83px

.login-content .error-wrapper
  margin-bottom: 40px

.login-content .error-wrapper h3
  text-transform: uppercase
  margin: 20px 0

#login-help
  position: absolute
  top: 20px
  right: 40px

/* Inner boxes */

.inner-box
  background: $alternateBackgroundColor
  border-radius: 5px
  padding: 10px 15px
  margin: 10px 0
  display: block
  text-decoration: none

.inner-box .inner-box-footer
  text-align: right
  font-size: 11px

.inner-box td
  line-height: 1.8

.inner-box h4
  margin-top: 5px

.inner-box [class*="col-"]
  margin-bottom: 0

.inner-box-with-border
  height: 100%
  border-radius: 4px
  border-style: solid
  border-width: 1px
  border-color: #0000000F
  padding: 10px
  display: block
  text-decoration: none

.inner-box-with-border h4
  margin-bottom: 10px
  margin-top: 0

/* Dropdown */

.dropdown
  position: absolute
  top: calc(100% + 28px)
  right: 0
  background: $lightBackgroundColor
  border: 1px solid $lightBorderColor
  border-radius: 4px
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.15)
  width: 180px
  opacity: 0
  z-index: 20
  visibility: hidden
  transform: translateY(-15px) scale(0.95)
  transform-origin: top right
  transition: all 300ms ease-in-out

.dropdown:after,
.dropdown:before
  bottom: 100%
  right: 8px
  border: solid transparent
  content: " "
  height: 0
  width: 0
  position: absolute
  pointer-events: none

.dropdown:after
  border-color: rgba(255, 255, 255, 0)
  border-bottom-color: #fff
  border-width: 10px
  margin-left: -10px

.dropdown:before
  border-color: rgba(237, 237, 237, 0)
  border-bottom-color: $lightBorderColor
  border-width: 11px
  margin-left: -11px

.dropdown.shown
  opacity: 1
  visibility: visible
  transform: translateY(0) scale(1)

.dropdown ul
  list-style: none
  margin: 0
  padding: 0

.dropdown ul li
  display: block
  margin: 0
  padding: 0

.dropdown ul li > a,
.dropdown ul li > span
  display: block
  padding: 13px 20px
  border-bottom: 1px solid $lightBorderColor
  text-decoration: none
  cursor: pointer
  font-weight: 700

.dropdown ul li > a:hover,
.dropdown ul li > span:hover
  background-color: $alternateBackgroundColor

.dropdown ul li.active
  background-color: $alternateBackgroundColor

.dropdown ul li:last-of-type > a,
.dropdown ul li > span:hover
  border-bottom: none

.business-canal-dropdown
  top: calc(100% + -3px)

.business-canal-dropdown::after
  content: none

.business-canal-dropdown::before
  content: none

.dropdownToggle
  cursor: pointer

.arrow-up
  display: inline-block
  border-left: 5px solid transparent
  border-right: 5px solid transparent
  border-bottom: 5px solid #9B9B9B

.arrow-down
  display: inline-block
  border-left: 5px solid transparent
  border-right: 5px solid transparent
  border-top: 5px solid #9B9B9B

/* Button */

.button
  position: relative
  display: inline-block
  border: 1px solid
  padding: 10px 30px
  margin-top: 10px
  border-radius: 7px
  font-weight: bold
  font-size: 13px
  cursor: pointer
  color: $placeholderColor
  border-color: $placeholderColor
  box-shadow: 0 2px 8px 0 rgba(17, 19, 50, 0.23)
  text-decoration: none
  transition: all 100ms linear

.button:hover
  filter: brightness(120%)

.button[data-type=icon-button]:hover
  filter: none
  color: $placeholderColor

.icon-button,
.button[data-type=icon-button]:not(.filter):hover
  box-shadow: 0 2px 8px 0 rgba(45, 45, 45, 0.25)

.icon-button
  opacity: 0.3

.button.small
  padding: 5px 10px
  margin-top: 0
  font-size: 10px
  border-radius: 3px

.button.full-width
  width: 100%
  text-align: center

.button.circle
  width: 46px
  height: 46px
  background: no-repeat center center
  background-size: 22px auto
  border-radius: 100%
  padding: 0

.button.rounded
  border-radius: 20px

.button[data-type=outline]
  box-shadow: none

.button.with-icon
  padding-left: 50px
  background: no-repeat left 20px center
  background-size: 20px auto

.button[data-icon=plus]
  background-image: url(../resources/img/icons/add-blue.svg)
  background-size: 16px auto

.button[data-icon=back]
  background-image: url(../resources/img/icons/chevron-left-blue.png)
  background-size: auto 18px

.button[data-icon=question]
  background-image: url(../resources/img/icons/question.svg)

.icon-button,
.button[data-type=icon-button]
  position: relative
  width: 32px
  height: 32px
  padding: 0
  margin: 0
  background: white
  border: none
  box-shadow: none
  border-radius: 3px
  overflow: hidden
  margin-bottom: -4px

.icon-button,
.button[data-type=icon-button]:not(.filter)
  border: 1px solid $lightBorderColor
  box-shadow: 0 2px 8px 0 rgba(45,45,45,0.08)

.icon-button:before,
.button[data-type=icon-button]:before
  position: absolute
  content: ' '
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: no-repeat center center
  background-size: 18px auto
  border-radius: 2px

.icon-button.active,
.button[data-type=icon-button].active
  background-color: $blueColor
  border-color: $blueColor
  box-shadow: 0 2px 8px 0 rgba(45, 45, 45, 0.25)

.icon-button.active:hover,
.button[data-type=icon-button].active:hover
  filter: brightness(120%)

.icon-butotn.active:before,
.button[data-type=icon-button].active:before
  filter: brightness(0) invert(1)

.button[data-type=icon-button] + .button[data-type=icon-button]
  margin-left: 10px

.button[data-type=icon-button][data-icon=mail]:before
  background-image: url(../resources/img/icons/button-mail.svg)
  background-size: 16px auto

.button[data-type=icon-button][data-icon=edit]:before
  background-image: url(../resources/img/icons/button-edit.svg)

.button[data-type=icon-button][data-icon=edit-white]:before
  background-image: url(../resources/img/icons/button-edit-white.svg)

.button[data-type=icon-button][data-icon=delete]:before
  background-image: url(../resources/img/icons/button-delete.svg)

.button[data-type=icon-button][data-icon=cancel]:before
  background-image: url(../resources/img/icons/button-cancel.svg)

.button[data-type=icon-button][data-icon=send]:before
  background-image: url(../resources/img/icons/button-send.svg)

.button[data-type=icon-button][data-icon=post]:before
  background-image: url(../resources/img/icons/button-post.svg)

.button[data-type=icon-button][data-icon=vau]:before
  color: #9B9B9B
  background-image: url(../resources/img/icons/vau.svg)

.button[data-type=icon-button][data-icon=verify]:before
  background-image: url(../resources/img/icons/check-grey.svg)

.button[data-type=icon-button][data-icon=print]:before
  background-image: url(../resources/img/icons/button-print.svg)

.button[data-type=icon-button][data-icon=view]:before
  background-image: url(../resources/img/icons/button-view.svg)

.button[data-type=icon-button][data-icon=download]:before
  background-image: url(../resources/img/icons/button-download.svg)

.button[data-type=icon-button][data-icon=pig]:before
  background-image: url(../resources/img/icons/pig.svg)

.button[data-type=icon-button][data-icon=filter]:before
  background-image: url(../resources/img/icons/filter.svg)

.button[data-type=icon-button][data-icon=settings]:before
  background-image: url(../resources/img/icons/menu-toggle.svg)

.button[data-type=icon-button][data-icon=arrow]:before
  background-image: url(../resources/img/icons/light-chevron-right.svg)
  background-size: 8px auto

.button[data-type=icon-button][data-icon=post].disabled
  background-color: white !important

.button[data-type=icon-button][data-icon=verify]:disabled
  background-color: #E5E6EC !important

.button[data-color=blue]
  background-color: $blueColor
  border-color: $blueColor
  color: white

.button[data-color=green]
  background-color: $greenColor
  border-color: $greenColor
  color: white

.button[data-color=red]
  background-color: $redColor
  border-color: $redColor
  color: white

.button[data-color=orange]
  background-color: $orangeColor
  border-color: $orangeColor
  color: white

.button[data-color=blue][data-type=link]
  background-color: white
  border: none
  color: $blueColor
  box-shadow: none
  padding-right: 5px
  padding-left: 5px

.button[data-color=blue][data-type=outline]
  background-color: white
  border-color: $blueColor
  color: $blueColor

.button[data-color=green][data-type=outline]
  background-color: white
  border-color: $greenColor
  color: $greenColor

.button[data-color=red][data-type=outline]
  background-color: white
  border-color: $redColor
  color: $redColor

.button[data-color=orange][data-type=outline]
  background-color: white
  border-color: $orangeColor
  color: $orangeColor

.button[data-color=gray][data-type=outline]
  background-color: white
  border-color: $placeholderColor
  color: $placeholderColor

.button[data-color=gray][data-type=outline]:hover
  border-color: $fontColor
  color: $fontColor

.button.subtle
  border: none
  box-shadow: none

.button.subtle[data-color=blue]
  background: none
  color: $blueColor

.button.subtle[data-color=silver]
  background: none
  color: silver

.button.subtle.uppercase
  text-transform: uppercase

.button[data-type=back-button]
  background: none
  box-shadow: none
  border: none
  color: $placeholderColor
  text-transform: uppercase
  background: url(../resources/img/icons/chevron-left-blue.png) no-repeat left center
  background-size: 12px auto
  min-height: 39px

.button[data-type=back-button]:hover
  color: $blueColor

.icon-button-label
  display: inline-block
  vertical-align: 10px
  font-size: 12px
  font-weight: bold
  margin-right: 15px

/* Button loading */

.button.loading:after
  position: absolute
  content: ' '
  top: calc(50% - 10px)
  left: 0
  right: 0
  margin: auto
  width: 20px
  height: 20px
  background: url(../resources/img/icons/loading-white.svg) no-repeat center center
  animation: rotation 1s infinite linear
  background-size: 18px auto

.button[data-type="outline"].loading:after,
.button.subtle.loading:after
  background-image: url(../resources/img/icons/loading-primary.svg)

.button.loading
  color: transparent !important
  background-image: none !important

.button.loading > *
  opacity: 0
  visibility: hidden

/* Note button */

.note-button
  font-size: 12px
  font-weight: bold

.note-button.question
  padding-left: 25px
  background: url(../resources/img/icons/question.svg) no-repeat left center
  background-size: 16px auto
  transition: all 100ms linear

.note-button.question:hover
  color: $blueColor

.button.toggle-button
  background: $blueColor url(../resources/img/icons/chevron-down-white.svg) no-repeat left 25px center
  background-size: 10px auto
  padding-left: 45px
  color: white
  font-size: 13px

.show-more-button
  color: $blueColor
  text-transform: uppercase
  font-weight: bold
  font-size: 12px
  text-decoration: none

.button.disabled,
button:disabled
  background-color: $placeholderColor !important
  border-color: $placeholderColor !important
  cursor: not-allowed !important
  color: white !important

.remove-button
  width: 26px
  height: 26px
  border-radius: 100%
  background: $redColor url(../resources/img/icons/cross-white.svg) no-repeat center center
  background-size: 12px
  box-shadow: 0 1px 8px 0 rgba(17, 19, 50, 0.23)
  cursor: pointer

/* File */

.file
  display: inline-block
  font-size: 12px
  text-decoration: none
  padding: 10px 0
  padding-left: 35px
  background: no-repeat left center
  background-size: 20px auto

.file-name
  max-width: 100%
  overflow-wrap: break-word

.file[data-type=pdf]
  background-image: url(../resources/img/icons/file-pdf.svg)

.file[data-type=general]
  background-image: url(../resources/img/icons/file.svg)

/* Input wrapper */

.input-wrapper,
.select-wrapper,
.checkbox-wrapper
  position: relative
  text-align: left
  padding-bottom: 30px

  input:disabled
    background-color: #F4F6F9
    user-select: none
    color: #9B9B9B !important

.content-header .input-wrapper,
.content-header .select-wrapper
  padding-bottom: 10px

.input-wrapper.inline
  display: inline-block

.input-wrapper label,
.select-wrapper label,
.checkbox-wrapper label
  font-size: 13px
  color: $fontColor
  font-weight: bold
  display: block
  margin-bottom: 10px

.select-wrapper.label-inline
  display: flex
  align-items: center
  gap: 10px

.select-wrapper.label-inline label
  margin-bottom: 0

.select-wrapper.label-inline > div
  flex-grow: 1


.select-divider
  margin-top: 8px

.input-wrapper .text-danger:not(:empty),
.select-wrapper .text-danger:not(:empty),
.checkbox-wrapper .text-danger:not(:empty),
.address-field .text-danger:not(:empty),
.text-danger:not(:empty)
  display: inline-block
  padding: 5px 5px 5px 0
  font-size: 11px
  font-weight: 600

.input-wrapper b
  border: none !important
  box-shadow: none !important
  background: none !important
  right: 10px !important
  color: $fontColor !important

.input-wrapper b i
  border-color: transparent transparent hsl(0, 0%, 80%) !important

.input-wrapper b:hover i
  border-color: transparent transparent hsl(0, 0%, 70%) !important

.react-numeric-input > b:nth-of-type(2) i
  border-color: hsl(0, 0%, 80%) transparent transparent !important

.react-numeric-input > b:nth-of-type(2):hover i
  border-color: hsl(0, 0%, 70%) transparent transparent !important

.input-wrapper input,
.input-wrapper textarea
  font-family: 'Open Sans', sans-serif
  padding: 15px 20px !important
  border: 1px solid $borderColor
  border-radius: 8px !important
  width: 100%
  font-size: 13px !important
  color: $fontColor !important
  transition: all 100ms linear

.input-wrapper input.subtle
  border: none
  border-bottom: 1px solid $borderColor
  border-radius: 0 !important

.content-wrapper .input-wrapper,
.content-wrapper .select-wrapper,
.checkbox-wrapper
  margin: 0 !important
  padding: 0 !important

.select-wrapper .react-select__control,
.select-wrapper .react-select__control.react-select__control--is-focused
  background: white
  border-color: $borderColor
  -webkit-flex-wrap: nowrap
  -ms-flex-wrap: nowrap
  flex-wrap: nowrap
  overflow: hidden


.select-wrapper .react-select__control.react-select__control--is-focused
  border-color: #9B9B9B
  outline: none
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1)

.select-wrapper .react-select__control.react-select__control--is-disabled
  background: $darkBackgroundColor

.react-select__input input:focus
  border-color: purple !important

.react-select__control:focus
  border-color: green !important

.content-header .select-wrapper .react-select__control
  font-weight: 700

.content-header .menu-toggle
  float: right
  margin-top: 11px
  margin-left: 20px
  width: 40px
  height: 40px
  position: relative

.content-header .menu-toggle > span
  content: ' '
  display: block
  height: 40px
  background: url(../resources/img/icons/export.svg) no-repeat center center
  background-size: 26px auto
  cursor: pointer

.content-wrapper .select-wrapper .react-select__control
  height: 30px
  min-height: 0
  font-size: 13px
  color: $placeholderColor
  border-radius: 8px

.react-select__dropdown-indicator
  padding-top: 0 !important
  padding-bottom: 0 !important

.react-select__value-container
  padding-top: 0 !important
  padding-bottom: 0 !important
  line-height: 30px

.react-select__control--is-disabled
  box-shadow: none !important

.input-wrapper .select-wrapper input:hover,
.input-wrapper .select-wrapper input.active,
.input-wrapper .select-wrapper input:focus,
.input-wrapper .select-wrapper input:active,
.input-wrapper .select-wrapper textarea:focus,
.input-wrapper .select-wrapper textarea:active
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1)

/* React Select */

.react-select__option.is-selected
  background-color: pink !important

.react-select__option.is-focused
  background-color: green !important

.content-wrapper .input-wrapper input
  padding: 5px 10px !important

.content-wrapper .input-wrapper textarea
  padding: 7px 10px !important

.content-wrapper .input-wrapper textarea.static
  padding: 0px !important
  background-color: $alternateBackgroundColor
  border: 0
  border-radius: 0 !important
  font-weight: bold
  font-family: 'Open Sans', sans-serif
  width: 100%
  font-size: 14px !important
  color: $fontColor !important
  transition: all 100ms linear

.content-wrapper .input-wrapper textarea.static:focus,
.content-wrapper .input-wrapper textarea.static:active
  box-shadow: none

.main-content .content-header .input-field
  margin-bottom: 0

.main-content .content-header .input-wrapper input
  padding: 10px 10px 10px 35px !important
  background: transparent

.main-content .content-header .input-wrapper.no-icon input
  padding-left: 10px !important

.input-wrapper input[data-type=search]
  padding-left: 40px !important
  background: url(../resources/img/icons/search-gray.svg) no-repeat center left 10px
  background-size: 16px auto

.main-content .content-header .input-wrapper:not(.no-icon) input[data-type=search]
  background: url(../resources/img/icons/search-gray.svg) no-repeat center left 10px
  background-size: 16px auto

.input-wrapper input:not(.subtle):hover,
.input-wrapper input:not(.subtle).active,
.input-wrapper input:not(.subtle):focus,
.input-wrapper input:not(.subtle):active,
.input-wrapper textarea:not(.subtle):focus,
.input-wrapper textarea:not(.subtle):active
  border-color: $placeholderColor
  outline: none
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.10)

.input-wrapper input.subtle:hover,
.input-wrapper input.subtle:active,
.input-wrapper input.subtle:focus
  border-color: $placeholderColor
  outline: none

.input-wrapper.checkbox-wrapper
  margin: 20px auto

.input-wrapper.checkbox-wrapper > input
  display: none

.input-wrapper.checkbox-wrapper > input:disabled + label
  cursor: not-allowed

.input-wrapper.checkbox-wrapper > input:disabled + label:before,
.input-wrapper.checkbox-wrapper > input:disabled:hover + label:before
  background-color: rgba(0, 0, 0, 0.1)
  border-color: rgba(0, 0, 0, 0.2)

.input-wrapper.checkbox-wrapper > input + label
  position: relative
  font-size: 12px
  font-weight: 400
  padding-left: 30px
  cursor: pointer
  transition: all 200ms ease-in-out
  margin: 0
  border-radius: 8px
  min-height: 20px

.input-wrapper.checkbox-wrapper > input + label:before
  position: absolute
  content: ' '
  top: 0
  left: 0
  width: 20px
  height: 20px
  border-radius: 8px
  background: no-repeat center center
  border: 1px solid $blueColor
  background-size: 11px auto
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05)
  transition: all 100ms ease-in-out

.input-wrapper.checkbox-wrapper > input + label:hover:before
  background-color: $borderColor
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15)

.input-wrapper.checkbox-wrapper > input:checked + label:before
  background-image: url(../resources/img/icons/check.svg)
  background-color: $blueColor
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25)

.input-note
  font-size: 10px
  margin-top: 5px
  font-weight: bold

.input-wrapper .small-input
  padding: 5px 10px
  margin: 0

.input-wrapper.select-wrapper .small-input
  height: 30px
  padding: 5px 10px

.input-wrapper .small-input.time-input
  width: 70px
  padding: 5px

.dropzone
  background: $alternateBackgroundColor
  border: 1px dashed $borderColor
  border-radius: 8px
  color: $placeholderColor
  cursor: pointer
  font-size: 12px
  transition: all 200ms linear
  width: 100%
  text-align: center
  min-height: 75px
  line-height: 75px

.dropzone.has-error
  border: 1px dashed $redColor

.dropzone:hover
  background-color: white

.dropzone-wrapper .button.small
  width: 100%
  text-align: center
  margin-top: 2px

/* Search */

.table-wrapper
  position: relative

.search-wrapper
  position: relative

.search-wrapper input
  width: 100%
  font-size: 16px
  padding: 18px 25px
  border-radius: 10px
  background: white
  border: 1px solid $borderColor
  outline: none
  box-shadow: 0 13px 28px 0 rgba(220,220,220,0.34)
  -webkit-appearance: none

.search-wrapper.no-border input
  border: none
  border-radius: 8px

.search-wrapper .button
  position: absolute
  top: 7px
  right: 7px
  margin-top: 0
  border-radius: 10px

.search-results-table
  border-bottom: 1px solid $borderColor

.content-wrapper .search-results-table
  border-top: 1px solid $borderColor

.search-results-table:not(.no-pointer) tr
  cursor: pointer

.search-results-table thead tr th
  text-align: left
  text-transform: uppercase
  font-size: 12px
  color: $fontColor
  border-bottom: 1px solid $borderColor
  padding: 15px 10px

.search-results-table tbody tr td
  padding: 15px 10px
  overflow-wrap: break-word
  word-break: normal

.search-results-table:not(.no-wrap) tbody tr td
  word-wrap: break-word
  word-break: break-all

.search-results-table
  .name
    width: 300px

  .phone-column
    width: 135px

  .partner-number
    width: 105px

  .date-of-birth
    width: 145px

  .ico
    width: 130px

  .segment
    width: 85px

  .checkbox-column
    width: 50px

  .button-column
    width: 150px

.search-results-table tbody tr:hover td,
.search-results-table tbody tr:nth-of-type(2n):hover td
  background: $alternateDarkBackgroundColor

.search-results-table tbody tr:nth-of-type(2n) td
  background-color: $alternateBackgroundColor

.search-results-table.collapsible tbody tr:nth-of-type(n + 6)
  display: none

.search-buttons .button
  padding-left: 0
  padding-right: 0
  margin-left: 50px

/* Stav uctu */
#pohladavky
  background: white
  border-bottom: $tableBorderColor

thead.sticky-header
  position: sticky
  top: 0
  background: white
  padding: 0 35px 0 20px
  box-shadow: 4px 2px 10px 0 rgba(218, 218, 218, 0.54)

thead.sticky-header th
  padding: 10px
  font-size: 11px
  text-transform: uppercase
  border: 0
  border-right: 1px solid $lightBorderColor
  border-bottom: 1px solid $lightBorderColor

thead.sticky-header.sortable.noBorder th
  padding: 10px
  font-size: 11px
  text-transform: none
  color: $steelGray
  border: 0
  border-right: 0
  border-bottom: 1px solid $lightBorderColor

thead.sticky-header.sortable tr th
  cursor: pointer

thead.sticky-header.sortable tr > th:nth-last-of-type(-n+2)
  border-right: none

thead.sticky-header.sortable tr th.disabled
  text-align: center

thead.sticky-header.sortable tr th:hover
  background-color: $alternateBackgroundColor

thead.sticky-header.sortable tr th.disabled:hover
  background-color: inherit
  cursor: inherit

thead.sticky-header.sortable tr th .sort
  width: 10px
  height: 15px
  background: url('../resources/img/icons/sort-default.svg') no-repeat center center
  background-size: contain

thead.sticky-header tr th.asc .sort
  background-image: url('../resources/img/icons/sort-asc.svg')

thead.sticky-header tr th.desc .sort
  background-image: url('../resources/img/icons/sort-desc.svg')


.table-wrapper.stav-uctu tr.selected
  font-weight: bold

.table-wrapper.stav-uctu tr.selected td:first-of-type
  border-left: 3px solid $blueColor

.zmluvne-vztahy .box-header-settings,
.miesto-spotreby .box-header-settings,
.table-wrapper .box-header-settings,
.responsive-table-wrapper .box-header-settings
  content: ' '
  width: 20px
  height: 20px
  background: url(../resources/img/icons/menu-toggle.svg) no-repeat center center
  background-size: 16px auto
  cursor: pointer
  opacity: 0.3
  transition: all 150ms linear
  vertical-align: middle

.zmluvne-vztahy .box-header-settings,
.miesto-spotreby .box-header-settings
  width: 32px
  height: 32px
  background-size: 24px auto

.zmluvne-vztahy .box-header-settings:hover,
.miesto-spotreby tr:hover .box-header-settings,
.table-wrapper tr:hover .box-header-settings,
.responsive-table-wrapper tr:hover .box-header-settings
  opacity: 0.6

.zmluvne-vztahy .box-header-settings:hover,
.miesto-spotreby .box-header-settings:hover,
.table-wrapper .box-header-settings:hover,
.responsive-table-wrapper .box-header-settings:hover
  opacity: 1
  transform: scale(1.1)

/* Sidebar menu */

.sidebar-toggle
  position: absolute
  height: 100%
  top: 0
  right: 0
  z-index: 6
  width: 40px
  background: white
  border-left: 1px solid $borderColor
  transform: translateX(100%)
  cursor: pointer
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1)

.sidebar-toggle.shown
  transform: translateX(0)

.sidebar-toggle span
  position: relative
  writing-mode: tb-rl
  transform: rotate(180deg)
  text-align: center
  width: 100%
  height: 100%
  display: block
  margin-left: 10px
  font-weight: bold
  font-size: 14px
  color: $blueColor

.sidebar-toggle span:after
  position: absolute
  content: ' '
  top: calc(50% + 70px)
  right: -10px
  width: 40px
  height: 40px
  background: url('../resources/img/icons/chevron-left-blue.png') no-repeat center center
  background-size: 10px auto
  transform: rotate(180deg)

.sidebar
  position: absolute
  height: 100%
  top: 0
  z-index: 6

.sidebar .sidebar-content
  overflow: auto

.sidebar.history-sidebar
  transform: translateX(100%)
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1)

.history-sidebar-opened .sidebar.history-sidebar
  transform: translateX(0)

.sidebar.history-sidebar .sidebar-content
  height: calc(100% - 60px)
  margin-top: 60px

.sidebar.history-sidebar.filter-opened .sidebar-content
  height: calc(100% - 220px)
  margin-top: 220px

.sidebar.history-sidebar.filter-opened.active .sidebar-content
  height: calc(100% - 255px) !important
  margin-top: 255px !important

.sidebar.history-sidebar.filter-opened .filter-content
  display: block

.sidebar .sidebar-header
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 60px
  padding: 10px 20px
  border-bottom: 1px solid $borderColor
  box-shadow: 4px 2px 10px 0 rgba(218, 218, 218, 0.54)
  z-index: 12

.sidebar .sidebar-header .button
  margin-top: 5px

.sidebar .sidebar-header .sidebar-header-top
  margin-bottom: 10px

.sidebar.history-sidebar.filter-opened .sidebar-header
  height: 220px

.sidebar.history-sidebar.filter-opened.active .sidebar-header
  height: 255px !important

.sidebar .sidebar-header h4
  font-size: 16px
  margin: 8px 0 0 0
  cursor: pointer

.sidebar .sidebar-header .filter.empty
  filter: grayscale(100%)
  opacity: 0.75

.sidebar .sidebar-header [class*="col-"]
  margin-bottom: 10px

.sidebar .sidebar-title
  padding-right: 20px
  background: url('../resources/img/icons/light-chevron-right.svg') no-repeat top 5px right
  background-size: 8px auto

.sidebar.menu-sidebar
  left: 0
  width: 64px
  background: $primaryBackgroundColor
  border-right: 1px solid $borderColor
  transition: width 400ms cubic-bezier(0.645, 0.045, 0.355, 1)

.sidebar.menu-sidebar .sidebar-content
  overflow-x: hidden

.menu-sidebar-opened .sidebar.menu-sidebar
  width: 220px

.side-menu .menu-item-label
  display: none

.menu-sidebar-opened .side-menu .menu-item-label
  display: inline

.side-menu
  width: 219px
  height: calc(100vh - 60px - 140px)
  overflow-x: hidden
  overflow-y: auto
  margin: 0
  padding: 16px 8px
  list-style-type: none

.side-menu li
  margin: 0
  margin-bottom: 8px
  padding: 0
  display: block

.side-menu li a
  position: relative
  padding: 20px
  font-size: 13px
  display: block
  color: $primaryFontColor
  font-weight: 400
  text-decoration: none

.menu-sidebar-opened .side-menu li a
  overflow: hidden
  padding: 11px 11px 11px 50px !important

.side-menu li.active a
  font-weight: bold
  padding: 30px 20px

.menu-sidebar-opened .side-menu li.active a
  padding: 11px 11px 11px 50px !important
  color: $primaryFontColor
  font-weight: 700
  overflow: visible

.sidebar-bottom
  position: absolute
  bottom: 0

.sidebar-bottom .collapse-menu .icon
  position: absolute
  content: ' '
  width: 40px
  height: 40px
  left: 0px
  top: 50%
  transform: translateY(-50%) rotate(180deg)
  background: no-repeat center center
  background-size: 15px auto
  transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1), left 200ms cubic-bezier(0.645, 0.045, 0.355, 1)

  &.double-arrow
    background-image: url(../resources/img/icons/chevron-left-blue.svg)

  .menu-sidebar-opened &
    transform: translateY(-50%) rotate(0deg)
    left: 90px
    top: 50%

.side-menu li a .icon
  position: absolute
  content: ' '
  width: 40px
  height: 40px
  left: 0px
  top: 50%
  transform: translateY(-50%)
  background: no-repeat center center
  background-size: 26px auto

  &.dashboard-icon
    background-image: url(../resources/img/icons/sidebar-menu-dashboard.svg)

  &.partner-icon
    background-image: url(../resources/img/icons/sidebar-menu-partner.svg)

  &.contracts-icon
    background-image: url(../resources/img/icons/sidebar-menu-contracts.svg)

  &.balance-icon
    background-image: url(../resources/img/icons/sidebar-menu-balance.svg)

  &.wallet-icon
    background-image: url(../resources/img/icons/wallet.svg)

  &.consent-icon
    background-image: url(../resources/img/icons/sidebar-menu-consent.svg)

  &.invoices-icon
    background-image: url(../resources/img/icons/sidebar-menu-invoices.svg)

  &.deposits-icon
    background-image: url(../resources/img/icons/sidebar-menu-deposits.svg)

  &.payment-calendar-icon
    background-image: url(../resources/img/icons/sidebar-menu-payment-calendar.svg)

  &.mandates-icon
    background-image: url(../resources/img/icons/sidebar-menu-mandates.svg)

  &.reminders-icon
    background-image: url(../resources/img/icons/sidebar-menu-reminders.svg)

.side-menu li.active a .icon
  filter: brightness(0) invert(1)

.side-menu li.active a:before
  position: absolute
  content: ' '
  width: 40px
  height: 40px
  left: 0px
  top: 50%
  transform: translateY(-50%)
  border-radius: 100%
  background: $blueColor
  box-shadow: 0 2px 12px 0 #1D96EE4D
  z-index: -1
  opacity: 0
  animation: fadeIn
  animation-duration: 300ms
  animation-fill-mode: forwards

.side-menu li a:hover
  background-color: #1D96EE1A
  border-radius: 10px



.sidebar.history-sidebar
  right: 0
  width: 350px
  background: white
  border-left: 1px solid $borderColor

.sidebar.history-sidebar .sidebar-content .history-wrapper
  position: relative
  min-height: 100%

.sidebar.history-sidebar .sidebar-content .history-wrapper .empty-content-container
  position: absolute
  width: 100%
  height: 100%
  background: $lightBackgroundColor
  z-index: 10
  margin-top: 0
  padding-top: 40px

.sidebar.history-sidebar .sidebar-content .history-wrapper .empty-content-container img
  width: 90px
  margin-bottom: 10px

.sidebar.history-sidebar .sidebar-content .history-wrapper:before
  position: absolute
  content: ' '
  top: 0
  left: 15px
  height: 100%
  border-left: 2px dashed $borderColor
  z-index: 1

.history-item-wrapper.even
  background-color: $lightBackgroundColor

.history-item-wrapper.odd
  background-color: $headerBackgroundColor

.history-item-wrapper.active
  border-radius: 5px
  box-shadow: inset 0 0 0 3px $blueColor

.history-item-wrapper[data-background-color=red]
  background-color: $redBackgroundColorLight !important

.history-item
  position: relative
  padding: 15px 20px 15px 0
  z-index: 2
  font-size: 12px

.history-item .history-header
  position: relative
  margin-left: 37px

.history-item .history-header:before
  position: absolute
  content: ' '
  top: 5px
  left: -27px
  width: 12px
  height: 12px
  border-radius: 100%
  background: white
  z-index: 2
  border: 3px solid $placeholderColor

.history-item .button
  padding: 3px 12px
  font-size: 10px
  margin: -1px 0 0 12px
  display: none

.history-item[data-label-color=green] .history-header:before
  border-color: $greenColor
  background-color: $greenColor

.history-item[data-label-color=red] .history-header:before
  border-color: $redColor
  background-color: $redColor

.history-item[data-label-color=blue] .history-header:before
  border-color: $blueColor
  background-color: $blueColor

.history-item[data-label-color=orange] .history-header:before
  border-color: $orangeColor
  background-color: $orangeColor

.history-item[data-label-color=yellow] .history-header:before
  border-color: $yellowColor

.history-item[data-label-color=grey] .history-header:before
  border-color: $placeholderColor
  background-color: $placeholderColor

.history-item .history-header
  height: 25px

.history-item .title
  font-size: 14px
  font-weight: bold

.history-item .time
  color: $placeholderColor
  font-weight: 700

.history-item .history-content
  position: relative
  margin-left: 37px

.history-item .content
  margin-top: 5px
  padding-right: 40px

.history-item .price
  max-width: 50px
  position: absolute
  bottom: 0
  right: 0
  font-weight: 700

.history-item .history-item-related-toggle
  margin-top: 15px
  text-transform: uppercase
  cursor: pointer

.history-item .history-item-related-toggle img
  transition: transform 400ms ease-in-out
  margin-left: 3px

.history-item .history-item-related-toggle.toggled img
  transform: rotate(180deg)

.history-item .history-item-related
  display: none

  &.visible
    display: block

.history-item .history-item-related .history-item
  padding-left: 15px
  padding-right: 0
  margin-left: 0

.history-item .history-item-related .history-item:before
  background-color: $placeholderColor

.history-item .history-item-related .history-item[data-label-color=blue]:before
  background-color: $blueColor

.history-item .history-item-related .history-item[data-label-color=green]:before
  background-color: $greenColor

.history-item .history-item-related .history-item[data-label-color=red]:before
  background-color: $redColor

.history-item .history-item-related .history-item[data-label-color=orange]:before
  background-color: $orangeColor

.history-item .history-item-related .history-item[data-label-color=yellow]:before
  background-color: $yellowColor

.history-item .history-item-related .history-item:before
  left: -27px

/* Rozpisy zaloh */

.rozpisy-zaloh-history
  padding: 0 10px
  overflow: hidden

.rozpisy-zaloh-history .rozpisy-zaloh-history-item
  border-left: 2px dashed $blueBorderColor

.rozpisy-zaloh-history .rozpisy-zaloh-history-item-content
  position: relative
  padding-left: 20px

.rozpisy-zaloh-history .rozpisy-zaloh-history-item-content:after
  position: absolute
  content: ' '
  top: calc(50% - 6px)
  left: -7px
  width: 12px
  height: 12px
  border-radius: 100%
  background: $blueColor
  box-shadow: 0 2px 7px 0 rgba(29, 150, 238, 0.35)

.rozpisy-zaloh-history .rozpisy-zaloh-history-item-content:before
  position: absolute
  content: ' '
  bottom: calc(50% + 1px)
  height: 100%
  background: white
  right: 100%
  width: 30px
  border-bottom: 2px dashed $blueBorderColor

.rozpisy-zaloh-history > div:first-of-type > .rozpisy-zaloh-history-item-content:before
  border-bottom: none

.rozpisy-zaloh-history .rozpisy-zaloh-history-item-content-wrapper
  position: relative

.rozpisy-zaloh-history .rozpisy-zaloh-history-item-content-wrapper:before
  position: absolute
  content: ' '
  right: calc(100% + 22px)
  top: calc(50% - 1px)
  height: 5000px
  background: white
  width: 40px

.rozpisy-zaloh-history .rozpisy-zaloh-history-item.last .rozpisy-zaloh-history-item-content-wrapper:before
  right: calc(100% + 20px)

.rozpisy-zaloh-history .rozpisy-zaloh-history-item .history-item
  max-width: 400px
  background: $alternateBackgroundColor
  padding-left: 40px
  border-radius: 4px
  margin-left: 0

.rozpisy-zaloh-history .rozpisy-zaloh-history-item .history-item:before
  left: 15px

.rozpisy-zaloh-history .rozpisy-zaloh-history-item .rozpisy-zaloh-history-item
  margin-left: 30px
  margin-top: 20px

/* Main content */

.main-content
  position: relative
  margin-left: 80px
  margin-right: 40px
  height: 100%
  font-size: 14px
  display: flex
  flex-direction: column
  transition: cubic-bezier(0.645, 0.045, 0.355, 1)
  transition: margin-right 400ms cubic-bezier(0.645, 0.045, 0.355, 1), margin-left 400ms cubic-bezier(0.645, 0.045, 0.355, 1)

.menu-sidebar-opened .main-content
  margin-left: 220px

.history-sidebar-opened .main-content
  margin-right: 350px

.main-content > form
  display: flex
  flex-direction: column
  justify-content: flex-start
  height: 100%

[data-page=dashboard] .main-content
  font-size: 12px

.main-content .content-wrapper
  position: relative
  overflow: auto
  padding: 20px
  flex-shrink: 1
  flex-grow: 1
  flex-basis: 1000%
  scroll-behavior: smooth

.main-content .content-wrapper.embed-wrapper
  padding: 0

.main-content .content-wrapper.embed-wrapper embed
  z-index: 2

.content-header
  background: white
  border-bottom: 1px solid $borderColor
  padding: 0 20px
  box-shadow: 4px 2px 10px 0 rgba(218, 218, 218, 0.54)
  min-height: min-content
  transition: opacity 400ms ease-in-out, box-shadow 100ms ease-in-out
  opacity: 0
  animation: fadeIn
  animation-duration: 400ms
  animation-delay: 200ms
  animation-fill-mode: forwards
  z-index: 6
  flex-shrink: 0

.content-header .button
  margin-bottom: 9px

.content-header .send-button
  margin: 14px 0 0 20px

.main-content .content-header .select-wrapper label,
.main-content .content-header .input-wrapper label
  font-weight: 400
  margin-top: 15px
  font-size: 12px

.main-content .content-header .select-wrapper select
  height: 40px
  margin-top: 10px
  font-weight: bold

.content-header .header-title
  font-weight: bold
  margin: 20px 0 0 20px

.content-header .header-row + .header-row
  border-top: 1px solid $lightBorderColor

.tab-navigation
  display: flex

.tab-navigation-link
  color: #3F627A
  padding: 18px
  text-decoration: none

.tab-navigation-link:hover
  text-decoration: underline


/* Content tables */

.content-table tbody tr td,
.content-table thead tr th
  padding: 5px 10px

.content-table thead tr th
  padding: 10px

.content-table tbody + thead tr th
  padding-top: 20px

.content-table tbody tr.active td
  background-color: $primaryBackgroundColor

.content-table:not(.bordered) tbody tr td:first-of-type
  border-top-left-radius: 5px
  border-bottom-left-radius: 5px

.content-table:not(.bordered) tbody tr td:last-of-type
  border-top-right-radius: 5px
  border-bottom-right-radius: 5px

.content-table tbody tr.inactive td
  background-color: $alternateBackgroundColor

.content-table.padded tbody tr td,
.content-table.padded thead tr th
  padding-top: 10px
  padding-bottom: 10px

.content-table.slim tbody tr td
  padding: 0 10px

.content-table.top-aligned tbody tr td
  vertical-align: top

.content-table.bordered tbody tr td,
.content-table.bordered thead tr th
  border-bottom: $tableBorderColor

.content-table.bordered tbody tr:last-of-type td
  border-bottom: none

.content-table.bordered-last-child tbody tr:last-of-type td
  border-bottom: $tableBorderColor

.content-table.striped tbody tr:nth-of-type(2n) td
  background-color: $alternateBackgroundColor

.content-table.hoverable tbody tr:hover td
  background-color: $alternateBackgroundColor

.content-table.clickable tbody tr td
  cursor: pointer

.content-table tbody[data-empty]:empty,
.search-results-table tbody[data-empty]:empty,
.content-table.has-error tbody[data-error]:empty,
.search-results-table.has-error tbody[data-error]:empty
  width: 100%
  height: 88px

.content-table tbody[data-empty]:empty:before,
.content-table.has-error tbody[data-error]:empty:before,
.search-results-table tbody[data-empty]:empty:before,
.search-results-table.has-error tbody[data-error]:empty:before
  position: absolute
  content: ' '
  height: 90px
  width: 100%
  top: 0
  left: 0
  right: 0
  margin: auto
  border-top: 1px solid $lightBorderColor
  border-bottom: 1px solid $lightBorderColor
  background: $alternateBackgroundColor

.content-table tbody[data-empty]:empty:after,
.content-table.has-error tbody[data-error]:empty:after,
.search-results-table tbody[data-empty]:empty:after,
.search-results-table.has-error tbody[data-error]:empty:after
  position: absolute
  content: attr(data-empty)
  text-align: center
  font-size: 14px
  color: $placeholderColor
  top: 0
  left: 0
  right: 0
  height: 90px
  line-height: 90px

.content-table.has-error tbody[data-error]:empty:after,
.search-results-table.has-error tbody[data-error]:empty:after
  content: attr(data-error)
  color: $redColor
  font-weight: bold

.content-table [class*="col-"],
.content-header [class*="col-"]
  margin-bottom: 0

.content-table tr[data-color="red"]
  background-color: $redBackgroundColor

.content-table tr[data-color="green"]
  background-color: $greenBackgroundColor

.content-table.highlighted tr:not(.highlighted) td
  position: relative

.content-table.highlighted tr:not(.highlighted) td > *
  opacity: 0.15

.content-table.highlighted tr[data-color="green"]:not(.highlighted)
  background-color: $greenBackgroundColorLight

.content-table.highlighted tr[data-color="red"]:not(.highlighted)
  background-color: $redBackgroundColorLight

.content-table.highlighted tr.highlighted a.highlighted,
.content-table.highlighted tr.highlighted span.highlighted
  color: $blueColor
  font-weight: bold
  text-decoration: none

.content-table tr div.highlightedAlert
  color: $redColor
  font-weight: bold

.content-table:nth-of-type(n+1)
  border-bottom: $tableBorderColor

.content-table:last-of-type
  border-bottom: none

.op-detail-table
  border-collapse: separate
  border-spacing: 0 6px

.op-detail-table td
  vertical-align: top

.op-detail-table .identifikatory-list
  display: flex
  flex-direction: column
  gap: 5px


/* Header amounts */

.header-amount
  padding-top: 20px
  text-align: right

.header-amount span
  font-size: 14px
  line-height: 16px

.header-amount strong span
  font-size: 16px
  font-weight: bold

/* Header infos */

.header-info
  padding-top: 10px
  text-align: left

.header-info span
  font-size: 14px
  line-height: 16px

.header-info strong span
  font-size: 16px
  font-weight: bold

/* Filter items */

.filter-items
  margin-top: -5px
  margin-bottom: 20px
  min-height: 36.8px

.filter-items:empty
  min-height: 0

.filter-item
  position: relative
  display: inline-block
  background: $filterBlueBackround
  border: 1px solid rgba($blueColor, 0.66)
  color: $blueColor
  font-weight: bold
  text-transform: uppercase
  padding: 5px 40px 5px 15px
  margin-top: 10px
  border-radius: 20px
  font-size: 11px
  margin-right: 10px

.filter-item[data-color="red"]
  border-color: rgba($redColor, 0.66)
  background-color: $filterRedBackround
  color: $redColor

.filter-item[data-color="green"]
  border-color: rgba($filterGreen, 0.66)
  background-color: $filterGreenBackground
  color: $filterGreen

.filter-item[data-color="purple"]
  border-color: rgba($filterPurple, 0.66)
  background-color: $filterPurpleBackground
  color: $filterPurple

.filter-item[data-color="orange"]
  border-color: rgba($orangeColor, 0.66)
  background-color: rgba($orangeColor, 0.15)
  color: $orangeColor

.filter-item[data-color="green"] .remove
  background-image: url(../resources/img/icons/cross-green.svg)

.filter-item[data-color="purple"] .remove
  background-image: url(../resources/img/icons/cross-purple.svg)

.filter-item[data-color="orange"] .remove
  background-image: url(../resources/img/icons/cross-orange.svg)

.filter-item .remove
  position: absolute
  top: 50%
  right: 15px
  transform: translateY(-50%)
  width: 15px
  height: 15px
  background: url(../resources/img/icons/cross-blue.svg) no-repeat center center
  background-size: 12px auto
  cursor: pointer

.filter-item[data-color="red"] .remove
  background-image: url(../resources/img/icons/cross-red.svg)

.inline-filter
  display: flex
  gap: 10px
  align-items: center

/* Payment tables */

.payment-table tbody tr td,
.payment-table thead tr th
  padding: 12px 10px
  font-size: 12px
  border-right: $tableBorderColor

.payment-table tbody tr td:first-of-type,
.payment-table thead tr th:first-of-type
  padding-left: 30px

.payment-table tbody tr td:last-of-type,
.payment-table thead tr th:last-of-type
  padding-right: 30px
  border-right: none

.payment-table thead tr th:not(:first-of-type):not(:last-of-type)
  font-weight: 400

.payment-table thead th
  border-bottom: $tableBorderColor

.payment-table thead th.green,
.payment-table tbody td.green
  background: $greenBackgroundColor

.payment-table thead th.blue,
.payment-table tbody td.blue
  background: $blueBackgroundColor

.payment-table thead th.red,
.payment-table tbody td.red
  background: $redBackgroundColor

.payment-table thead th.yellow,
.payment-table tbody td.yellow
  background: $yellowBackroundColor

/* Plan splatok tables */

.plan-splatok-table tbody tr td,
.plan-splatok-table thead tr th
  padding: 12px 10px
  font-size: 14px
  border-right: $tableBorderColor

.plan-splatok-table tbody tr td:first-of-type,
.plan-splatok-table thead tr th:first-of-type
  padding-left: 30px

.plan-splatok-table tbody tr td:last-of-type,
.plan-splatok-table thead tr th:last-of-type
  padding-right: 30px
  border-right: none

.plan-splatok-table thead tr th:not(:first-of-type):not(:last-of-type)
  font-weight: 400

.plan-splatok-table tbody tr:nth-of-type(2n) td
  background: $alternateBackgroundColor

.plan-splatok-table tbody tr:hover td
  background: $alternateBackgroundColor

.plan-splatok-table thead th
  border-bottom: $tableBorderColor

/* Grid */

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto
  padding-right: 10px
  padding-left: 10px

.row
  margin-right: -10px
  margin-left: -10px

.row.bordered > [class*="col-"] + [class*="col-"]
  border-left: 1px solid $lightBorderColor

/* Loading */

.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: $lightBackgroundColor
  display: flex
  justify-content: center
  align-items: center
  z-index: 100

.element-loading
  top: 0
  left: 0
  position: absolute
  display: flex
  align-items: center
  width: 100%
  height: 100%
  background: white
  z-index: 100
  opacity: 0
  visibility: hidden
  transition: all 200ms ease-in-out

.cornered-loading .element-loading
  border-radius: 5px
  overflow: hidden

.loader-box
  display: flex
  justify-content: center
  align-items: center
  height: 80px

.page-loading.shown,
.element-loading.shown
  opacity: 1
  visibility: visible

.loader,
.loader:before,
.loader:after
  background: $blueColor
  -webkit-animation: load1 1s infinite ease-in-out
  animation: loading 1s infinite ease-in-out
  width: 0.5em
  height: 2em
  border-radius: 3px

.loader
  color: $blueColor
  text-indent: -9999em
  margin: 0 auto
  position: relative
  font-size: 11px
  -webkit-transform: translateZ(0)
  -ms-transform: translateZ(0)
  transform: translateZ(0)
  -webkit-animation-delay: -0.16s
  animation-delay: -0.16s
  margin-top: 10px

.loader:before,
.loader:after
  position: absolute
  top: 0
  content: ''

.loader:before
  left: -1.25em
  -webkit-animation-delay: -0.32s
  animation-delay: -0.32s

.loader:after
  left: 1.25em

.fade-in
  opacity: 0
  animation: fadeInZoom
  animation-duration: 300ms
  animation-delay: 600ms
  animation-fill-mode: forwards

/* Datepicker */

.react-datepicker
  border-color: $borderColor
  box-shadow: 0 7px 20px 0 rgba(174, 174, 174, 0.3)

.react-datepicker__triangle
  display: none

.react-datepicker:after,
.react-datepicker:before
  bottom: 100%
  left: 20px
  border: solid transparent
  content: ' '
  height: 0
  width: 0
  position: absolute
  pointer-events: none

.tooltip-right .react-datepicker:after,
.tooltip-right .react-datepicker:before
  left: auto
  right: 20px

.tooltip-right .react-datepicker:after
  right: 21px

.react-datepicker:after
  border-color: rgba(255, 255, 255, 0)
  border-bottom-color: $alternateBackgroundColor
  border-width: 8px
  margin-left: -8px

.react-datepicker:before
  border-color: rgba(237, 237, 237, 0)
  border-bottom-color: $borderColor
  border-width: 9px
  margin-left: -9px

.react-datepicker__header
  background: $alternateBackgroundColor

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before
  border-bottom-color: $lightBorderColor

.react-datepicker-popper
  width: max-content

.react-datepicker__header
  border-bottom-color: $lightBorderColor

.react-datepicker__current-month,
.react-datepicker-time__header
  text-transform: uppercase
  font-size: 12px
  line-height: 20px
  margin-bottom: 5px
  padding-bottom: 5px
  border-bottom: 1px solid $lightBorderColor

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view
  font-weight: bold

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range
  background-color: $blueColor
  font-weight: bold

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown
  background-color: $alternateBackgroundColor
  border-color: $borderColor
  box-shadow: 0 7px 20px 0 rgba(174, 174, 174, 0.3)

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll
  width: 75px

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option
  font-weight: bold
  line-height: 27px
  color: $placeholderColor

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover
  color: $fontColor
  background: transparent

.react-datepicker__navigation
  border-width: 0.35rem

.react-datepicker__day-name
  text-transform: uppercase
  font-size: 11px
  color: $placeholderColor
  font-weight: bold

.react-datepicker__close-icon::after
  background-color: transparent !important
  border-radius: 0 !important
  bottom: 0 !important
  box-sizing: border-box !important
  color: #cccccc !important
  content: '\D7' !important
  cursor: pointer !important
  font-size: 22px !important
  height: 16px !important
  width: 16px !important
  line-height: 1 !important
  margin: 0 !important
  position: absolute !important
  right: 7px !important
  text-align: center !important
  top: 50% !important
  font-weight: bold !important
  transform: translateY(-50%) !important
  margin-top: -6px !important

/* React select */

.react-select__option
  font-size: 13px !important

.react-select__option--is-focused
  background: $alternateBackgroundColor !important

.react-select__option--is-selected
  background-color: $alternateDarkBackgroundColor !important
  color: black !important
  font-weight: bold !important

/* Time range */

#react-time-range
  padding: 0 !important

#start-component
  margin-right: 20px !important

#react-time-range .error
  display: block
  margin-top: 3px
  font-size: 11px
  font-weight: 600
  padding-left: 0

/* Animations */

@keyframes rotation
  0%
    -webkit-transform: rotate(0deg)
    transform: rotate(0deg)

  100%
    -webkit-transform: rotate(359deg)
    transform: rotate(359deg)

@keyframes fadeIn
  from
    opacity: 0

  to
    opacity: 1

@keyframes fadeInZoom
  from
    opacity: 0
    transform: scale(0.95)

  to
    opacity: 1
    transform: scale(1)

/* Loading */

@keyframes loading
  0%,
  80%,
  100%
    box-shadow: 0 0
    height: 2em

  40%
    box-shadow: 0 -2em
    height: 2.5em

.react-datepicker-wrapper
  width: 100%
  background: url('../resources/img/icons/calendar.svg') no-repeat left 10px center
  background-size: 17px auto

.react-datepicker__input-container
  width: 100%

.react-numeric-input
  width: 100%

.tippy-tooltip
  font-size: 12px !important
  font-weight: 600

.tippy-tooltip [x-circle]
  border-radius: 0 !important

.info-text
  text-align: start

.empty-content-container,
.failure-content-container
  text-align: center
  margin: 40px 0

  img
    width: 120px
    display: block
    margin: 40px auto 20px auto

  p
    padding-top: 40px
    font-size: 15px
    color: #9B9B9B
    font-weight: bold
    max-width: 300px
    margin-left: auto
    margin-right: auto
    line-height: 1.75

  p.message.fail
    padding: 0
    font-size: 17px
    color: $redColor
    font-weight: bold
    margin-left: auto
    margin-right: auto
    line-height: 1.75

  p.message.success
    padding: 0
    font-size: 17px
    color: $greenColor
    font-weight: bold
    margin-left: auto
    margin-right: auto
    line-height: 1.75

  &.tiny
    margin: 2px 0
    img
      width: 50px

    p
      padding-top: 0px
      font-size: 14px

.close
  position: absolute
  right: 25px
  top: 25px
  width: 25px
  height: 25px
  opacity: 0.3
  cursor: pointer

.close:hover
  opacity: 1

.upload-button-wrapper
  label
    display: block
    cursor: pointer

  label > *
    pointer-events: none

  .upload-button-file
    width: 0
    height: 0
    opacity: 0
    overflow: hidden
    position: absolute
    z-index: -100000000

.text-danger
  display: block
  color: $redColor

.text-warn
  display: block
  color: $orangeColor

.danger-box
  border: 2px solid $redColor !important

.has-error,
.has-error:hover,
.has-error:focus
  label
    color: $redColor
  input
    border: 2px solid $redColor
  .react-select__control
    border: 2px solid $redColor
  .text-danger
    display: block
    color: $redColor
  .react-numeric-input input
    border: 2px solid $redColor !important

.react-select__placeholder
  white-space: nowrap
  overflow: hidden
  width: calc(100% - 15px)
  text-overflow: ellipsis

input:-ms-input-placeholder
  color: $placeholderColor !important
  font-weight: 400 !important

.version
  font-size: 12px
  color: $placeholderColor
  cursor: default !important
  background-color: $lightBackgroundColor !important

.forbidden-page,
.not-found-page
  h4
    margin: 30px auto
    margin-top: 90px
    display: block
    text-align: center
    font-size: 50px

  .content
    text-align: center

.info-icon
  position: absolute
  content: ' '
  width: 20px
  height: 20px
  background: url(../resources/img/icons/info-blue.svg) no-repeat right center
  background-size: 13px auto
  right: 0
  top: 50%
  transform: translateY(-50%)

td .info-icon,
.box-header .info-icon
  display: inline-block
  position: relative
  top: auto
  right: 0
  transform: none
  vertical-align: -4px

.tooltip-label:after
  position: absolute
  content: ' '
  top: 0
  margin-left: 4px
  width: 17px
  height: 17px
  background: url(../resources/img/icons/info-blue.svg) no-repeat right center
  background-size: 13px auto

.react-select__value-container
  height: 38px

.active-filters.blue .react-select__placeholder
  color: $blueColor

.active-filters.green .react-select__placeholder
  color: $filterGreen

.active-filters.purple .react-select__placeholder
  color: $filterPurple

.active-filters.orange .react-select__placeholder
  color: $orangeColor

.tooltip-flex-holder
  display: flex
  justify-content: space-between

/* Zmluvny ucet - list item */

.zmluvny-ucet-header
  padding-right: 10px

.zmluvny-ucet-header.red
  color: $redColor

.zmluvny-ucet-header.green
  color: $greenColor

.warning
  background-color: $redBackgroundColorLight !important

.ql-container
  min-height: 500px

.ql-editor
  font-size: 14px

.ql-editor p
  margin: 15px 0
  line-height: 32px

.ql-editor .editor-placeholder
  padding: 2px 4px
  border: 1px solid $redColor
  color: $redColor !important
  font-weight: 700

.box.collapsible .box-content,
.collapsible .collapsible-content
  overflow: hidden
  height: 0
  padding: 0

.box.collapsible .box-content.opened
  height: auto

.collapsible .collapsible-content.opened
  height: auto

.box.collapsible .box-header
  cursor: pointer

.collapsible .collapsible-header
  cursor: pointer
  background-color: $alternateBackgroundColor
  padding: 0 12px
  border-radius: 5px
  margin: 0px 20px 20px 20px

.collapsible .collapsible-header.opened
  margin-bottom: 0px

.collapsible .collapsible-header > span
  margin: 10px 0

.collapsible .collapsible-header > div
  display: inline
  margin: 10px 0

.box.collapsible .box-header .button[data-type=icon-button]:not(.icon-button),
.collapsible .collapsible-header .button[data-type=icon-button],
.collapsible-subheader .button[data-type=icon-button]
  width: 23px
  height: 23px
  background: none
  border: none !important
  box-shadow: none !important
  transform: rotate(0deg)
  transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1)

.box.collapsible .box-header .button[data-type=icon-button].opened,
.collapsible .collapsible-header .button[data-type=icon-button].opened,
.collapsible-subheader .button[data-type=icon-button].opened
  transform: rotate(90deg)

.miesto-spotreby > .box-content > .ReactCollapse--collapse > .ReactCollapse--content
  .zmluvny-ucet,
  .zmluvny-vztah-detail
    padding: 15px 0
    display: flex
    flex-wrap: wrap
    position: relative

.miesto-spotreby > .box-content > .ReactCollapse--collapse > .ReactCollapse--content > .zmluvny-ucet
  margin-left: 0px
  width: 100%

  &:first-of-type
    border-top: none

.ReactCollapse--collapse[aria-hidden='true'] > .ReactCollapse--content .empty-state
  visibility: hidden

.miesto-spotreby .zmluvny-ucet
  background-color: #F8F8F8
  border-top: 1px solid #DFDFDF
  border-bottom: 1px solid #DFDFDF

.miesto-spotreby .zmluvny-ucet,
.miesto-spotreby .zmluvny-vztah,
.miesto-spotreby .zmluvny-vztah-detail
  padding: 15px 10px

.miesto-spotreby .zmluvny-vztah-detail
  background-color: #F8F8F8

.zmluvny-vztah.highlighted
  margin: 0
  background-color: $yellowBackroundColor

.miesto-spotreby .zmluvny-vztah > div.highlighted
  background-color: $yellowBackroundColor

.miesto-spotreby .hr
  margin: 0

.miesto-spotreby .zmluvny-vztah .box-header-dropdown .dropdown
  top: calc(100% + 10px) !important
  right: -3px

.prepis-zmluvny-vztah-ukony
  td
    padding-bottom: 0

  td:first-of-type
    padding-left: 0

.content-table .right-button-space
  width: calc(100% - 40px)
  float: left

.content-table .right-button
  width: 32px
  float: right
  margin-top: 5px

.custom-empty-state
  height: 60px
  text-align: center
  padding-top: 20px
  color: #9B9B9B

.button.bleached[data-color=blue]::before
  filter: brightness(100)

.ReactCollapse--collapse
  transition: height 500ms

.field-has-invalid-pattern
  background-color: $yellowBackroundColor

.message-contact-fields-validation
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  margin: 20px 0

  button.button
    margin-top: 0

.ukon-state
  line-height: 21px
  margin-bottom: 2px
  margin-right: 5px

.ukon-state.label.circle[data-color=yellow]
  background-color: transparent
  border: 3px solid $yellowColor

a.zmluvny-vztah-op-detail:hover
  cursor: pointer
  text-decoration: underline

.digitalizacia-widget .react-select__menu
  position: relative !important
  padding-left: 10px !important
  box-shadow: none !important

.radio-group-container
  display: flex
  flex-wrap: wrap

  .radio
    display: inline
    margin-right: 10px
    line-height: 30px
    white-space: nowrap
    input[type="radio"]
      position: absolute
      opacity: 0
      + .radio-label
        &:before
          content: ''
          background-color: white
          border-radius: 100%
          border: 1px solid darken($borderColor, 25%)
          display: inline-block
          width: 20px
          height: 20px
          position: relative
          bottom: 1px
          margin-right: 10px
          vertical-align: middle
          cursor: pointer
          text-align: center
          transition: all 250ms ease

      &:checked
        + .radio-label
          &:before
            background-color: $blueColor
            box-shadow: inset 0 0 0 4px white

      &:focus
        + .radio-label
          &:before
            outline: none
            border-color: $blueColor

      &:disabled
        + .radio-label
          &:before
            box-shadow: inset 0 0 0 4px $borderColor
            border-color: darken($borderColor, 25%)
            background: darken($borderColor, 25%)

      + .radio-label
        &:empty
          &:before
            margin-right: 0

.picker-field
  padding: 12px 0

.picker-field-options
  display: flex
  gap: 8px
  flex-wrap: wrap

.picker-field-option
  cursor: pointer
  background: $lightBackgroundColor
  border: 1px solid $borderColor
  border-radius: 8px
  padding: 6px 8px
  text-align: left

.picker-field-option:hover
  background: $alternateBackgroundColor

.picker-field-option-selected,
.picker-field-option-selected:hover
  background: $blueColor
  color: #fff
  border-color: $blueColor

.boolean-switch-button
  display: block
  background: none
  border: none
  cursor: pointer
  padding: 0
  margin: 0
  border-radius: 100px
  background: rgba(0, 0, 0, 0.25)
  width: 45px
  height: 22px
  position: relative


.boolean-switch-button.boolean-switch-active
  background: $blueColor
  background-image: url(../resources/img/icons/check-white.svg)
  background-size: 12px
  background-position: 8px 6px
  background-repeat: no-repeat

.boolean-switch-handle
  display: block
  width: 18px
  height: 18px
  border-radius: 50%
  background: #fff
  position: absolute
  left: 3px
  top: 2px
  transition: left 250ms ease

.boolean-switch-active .boolean-switch-handle
  left: 25px

/* Sprava suhlasov */

.consent-widget
  margin-bottom: 16px

  .react-select__menu
    position: relative !important
    padding-left: 10px !important
    box-shadow: none !important

  .react-select__option:not(:last-child)
    margin-bottom: 8px

.consent-page .content-header .header-title
  font-weight: bold
  margin: 20px
  max-width: 550px


.consent-main-category
  overflow: hidden
  margin-bottom: 40px

.consent-warning-header
  background: $warningColor
  background: linear-gradient(90deg, rgba(227,148,16,1) 0%, rgba(237,175,72,1) 35%)
  display: flex
  align-items: center
  padding: 20px 15px
  color: #fff

.consent-warning-icon
  display: block
  width: 24px
  height: 24px
  margin-right: 20px

.consent-main-category-body
  border: 3px solid $warningColor
  border-top: none
  border-radius: 0 0 4px 4px
  background: $lightBackgroundColor
  padding: 15px 15px 0 15px

.consent-category.box-header
  background-color: $lightBackgroundColor
  border-bottom: none

.consent-category-content
  padding: 0 14px

.consent-collapsible-header
  display: flex
  align-items: center
  padding: 15px
  gap: 24px

.consent-collapsible-right-box
  display: flex
  align-items: center
  gap: 24px

.consent-collapsible-warning
  display: flex
  width: 40px
  height: 40px
  padding: 8px
  justify-content: center
  align-items: center
  border-radius: 40px
  background: $lightBackgroundColor
  color: $warningColor

.consent-collapsible-name
  font-size: 16px

.consent-collapsible-category
  font-size: 14px
  font-weight: 600
  color: $lightFontColor
  margin-bottom: 4px

.consent-collapsible-divider
  margin-left: auto

.consent-collapsible-version
  font-size: 14px
  color: $lightFontColor
  align-self: end

.consent-collapsible-date
  font-size: 14px
  font-weight: 600
  color: $lightFontColor

.consent-badge
  .badge-box
    padding: 4px 8px
    border-radius: 8px
    display: inline-flex
    gap: 8px
    align-items: center
    font-weight: 600

    img
      height: 16px
      width: 16px
      display: block

  .green
    background: #CBECC9
    color: #178D11

  .red
    background: #FFCCCC
    color: #E70000

  .gray
    background: #E2E2E2
    color: #626262

.consent-action-container
  display: flex
  align-items: center
  margin: 15px

.consent-products-container
  margin: 15px

.consent-action-first-column
  min-width: 200px
  margin-right: 16px

.consent-action-second-column
  font-weight: 700

.consent-action-button
  margin-left: auto

.consent-outher-collabsible
  border-radius: 5px
  border: 1px solid #DFDFDF
  margin: 15px

.consent-inner-collabsible
  display: flex
  align-items: center
  justify-content: space-between
  cursor: pointer
  padding: 15px

.consent-inner-collabsible-name
  color: #222
  font-size: 14px
  font-weight: 700

.consent-last-statement-container
  background-color: $alternateBackgroundColor
  padding: 32px 20px
  display: flex
  flex-direction: column
  gap: 40px

.consent-last-statement
  display: flex

.consent-last-statement-column
  width: 50%

  &:first-child
    margin-right: 64px
    border-right: 1px solid $borderColor

  ul
    padding: 0
    margin: 0
    list-style: none

    li
      padding: 5px 0
      font-size: 14px
      display: flex
      align-items: center
      justify-content: space-between

.consent-last-statement-column-1
  display: inline-block
  width: 40%

.consent-last-statement-column-2
  font-weight: 700
  width: 60%
  text-wrap: wrap

.consent-last-statement-history-link a
  color: $blueColor
  font-weight: 600
  text-decoration: none


.consent-true
  color: $greenColor

.consent-false
  color: $redColor

.consent-definition
  background-color: $alternateBackgroundColor
  padding: 32px 20px

  ul
    padding: 0
    margin: 0
    list-style: none

    li
      padding: 6px 16px 6px 0
      font-size: 14px
      display: flex

.consent-definition-column-1
  display: inline-block
  min-width: 350px

.consent-definition-column-2
  font-weight: 700

.consent-text
  background-color: $alternateBackgroundColor
  padding: 32px 20px

.consent-history
  border: 1px solid $borderColor
  border-radius: 4px
  overflow: hidden

.consent-history-header
  font-size: 16px
  font-weight: 600
  padding: 20px 24px
  background: $alternateBackgroundColor

.consent-history-body
  padding: 32px 20px
  background-color: $lightBackgroundColor
  display: flex
  flex-direction: column
  gap: 10px

.consent-warning
  overflow: hidden
  border-radius: 4px
  border: 1px solid $borderColor
  background: #FFF
  box-shadow: 0px 2px 6px 0px rgba(189, 189, 189, 0.26)

.consent-warning-description
  margin-bottom: 24px

.consent-warning-content
  padding: 20px

.consent-warning-sub-container
  display: flex
  flex-direction: column
  gap: 16px

.consent-warning-box
  display: flex
  border-radius: 4px
  background: $alternateBackgroundColor
  align-items: center
  padding: 14px 20px
  flex-grow: 1
  gap: 16px

.consent-warning-box-category-name
  color: $lightFontColor
  font-size: 14px
  font-weight: 600
  margin-bottom: 4px

.consent-warning-box-consent-name
  font-size: 14px
  font-weight: 600

.consent-warning-box-badges
  display: flex
  margin-left: auto
  gap: 32px

.consent-warning-change-icon
  display: block
  width: 28px
  height: 28px

.consent-warning-sub-description
  color: $lightFontColor
  font-size: 14px
  font-weight: 600
  margin-bottom: 16px
  margin-left: 50px
  margin-top: 24px

.consent-warning-sub-box-container
  display: flex

.consent-warning-sub-arrow-container
  position: relative
  width: 50px

.consent-warning-sub-arrow
  position: absolute
  left: 50%
  top: -50px
  height: 90px
  transform: translateX(-50%)

.email-badge-wrapper
  display: flex
  gap: 8px
  justify-content: space-between
  padding: 0 74px 4px 0
  .email-badges
    display: flex
    justify-items: flex-start
    justify-content: end
    gap: 4px
    .label
      max-height: 21px

.fakty-modal
  z-index: 3
  position: absolute
  background: #FFFFFF
  border: 1px solid #E7E7E7
  box-shadow: 0px 4px 8px 0px #0000001A
  height: 100%
  top: 0
  max-width: 50%
  right: 0
  .header
    display: flex
    padding: 16px
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid #DFDFDF
    box-shadow: 4px 2px 10px 0 rgba(218, 218, 218, 0.54)

  .content
    padding-top: 10px
    padding-inline: 6px
    overflow: auto
    max-height: calc(100% - 52px)

.fakty-modal-extra-width
  max-width: 55%

/* Upload field */

.upload-field
   border: 1px solid #0000000F
   border-radius: 4px
   padding: 10px

.upload-dropzone-wrapper
  .dropzone
    min-height: 50px
    max-height: 50px
    margin-top: 10px
    background: #1D96EE14
    border: 2px solid #1D96EE99
    border-style: dashed
    color: $blueColor
    &:hover
        opacity: 0.6
    p
        font-weight: 700
        line-height: 46px
        margin: 0
    &.disabled
     background: $alternateBackgroundColor
     border-color: #DFDFDF
     cursor: not-allowed
     color: $lightFontColor

.upload-empty-state
   display: flex
   justify-content: center
   align-items: center
   padding: 15px 0
   margin-top: 10px
   div p
      color: #CACACA
      font-weight: 700
      margin: 10px 0 0 0

.empty-icon
   background-image: url(../resources/img/icons/empty.svg)
   background-size: 45.38px 45.66px
   width: 45.38px
   height: 45.66px

.file-box
   display: flex
   gap: 25px
   width: 100%
   align-items: center
   justify-content: space-between
   padding: 5px 10px 5px 5px
   height: 40px
   border-radius: 5px
   .actions-btn-box
      display: flex
      align-items: center
   &:hover
      background: $alternateBackgroundColor
      .remove-file-btn
        display: block
      .change-file-btn
        display: block

.file-list
   padding: 0 0 15px 0
   display: flex
   gap: 10px
   flex-wrap: wrap
   margin-top: 10px

.change-file-btn
   display: none

.remove-file-btn
   display: none
   width: 26px
   height: 25px
   background-image: url(../resources/img/icons/delete-file.svg)
   background-size: 26px 28px
   cursor: pointer

/* Generické úkony */

.generic-field
  padding: 10px

.generate-document-box
  display: flex
  align-items: center
  justify-content: space-between

.generic-field.with-background
  background: $alternateBackgroundColor
  border-radius: 5px

.attr-vystupne-dokumenty-buttons
  display: flex
  gap: 10px
  align-items: center

.notification-box
  margin-top: 10px
  display: flex
  align-items: center
  justify-content: space-between

.lead-header-box
  display: flex
  align-items: center
  justify-content: space-between

.flex-box-center
  display: flex
  align-items: center
  justify-content: space-between

.search-icon
  width: 24px
  height: 24px
  color: $blueColor

.lead-search-wrapper
  width: 100%
  max-width: 600px
  margin-left: 20px
  margin-right: 20px

.search-wrapper .search-icon
  position: absolute
  top: 7px
  right: 7px
  margin-top: 0
  border-radius: 10px

.lead-search-input
  max-width: 600px
  height: 32px
  width: 100%
  font-size: 16px
  padding: 18px 25px
  border-radius: 10px
  background: $lightBackgroundColor
  border: 1px solid $borderColor
  outline: none
  box-shadow: 0 13px 28px 0 rgba(220,220,220,0.34)
  -webkit-appearance: none

.btn-box-modal-footer
  display: flex
  flex-direction: row
  column-gap: 10px

.lead-table-footer
  margin-top: 15px

.lead .content-table.padded tbody tr td
  padding: 8px 5px

.main-lead-box
  position: relative
  min-height: 500px

.lead-table-value
  display: flex
  align-items: center
  & svg
    margin-right: 5px
  & p
    margin: 4px 0
    &.strong
      font-weight: 600

.table-empty-state
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  padding: 60px 0
  .empty-box
    text-align: justify
    text-align-last: center
    font-size: 13px
    color: $placeholderColor
    font-weight: bold
    & p
      width: 260px
      margin-top: 30px


.page-sizes-box
  display: flex
  flex-direction: row
  column-gap: 5px
  & button
    color: $blackColor
    background: $whiteColor
    border: 1px solid #10234A26
    border-radius: 8px
    padding: 10px
    height: 38px
    cursor: pointer
    &:hover
      opacity: 0.5
    &.selected
      color: $blueColor
      font-weight: 700

/* custom modal */

.custom-modal-wrapper
  border-radius: 16px

.custom-modal-header
  padding: 15px
  border-bottom: 1px solid $borderColor
  display: flex
  justify-content: space-between
  align-items: center
  & .close-btn
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    opacity: 0.3
    cursor: pointer
    &:hover
      opacity: 1

.custom-modal-footer
  padding: 15px
  box-shadow: none !important

.custom-modal-content
  padding: 15px

.modal-filter-body-layout
  display: flex
  & .filter-menu-body
    width: 75%

.left-side-menu-box
  display: flex
  width: 25%
  flex-direction: column
  background: #F5F5F5
  .menu-item
    background: transparent
    padding: 15px 12px
    border-bottom: 1px solid $borderColor
    cursor: pointer
    &.last
      border-bottom: none
    &.active
      background: $lightBackgroundColor

.filter-modal-body
  padding: 0px

.form-filter
  padding: 15px

.form-filter-label
  color: $steelGray
  margin: 4px 0
  font-weight: 600
  font-size: 12px

.lead-filter-tag-box
  display: flex
  flex-direction: row
  gap: 10px
  flex-wrap: wrap
  & .filter-tag
    display: flex
    align-items: center
    justify-content: center
    border-radius: 20px
    height: 24px
    padding: 0 8px
    color: $whiteColor
    background: $blueColor
    & .remove
      margin-left: 6px
      cursor: pointer
      display: flex

.filter-btn-counter
  width: 16px
  height: 16px
  border-radius: 999px
  margin-left: 10px
  color: $blueColor
  background: $lightBackgroundColor
  display: flex
  align-items: center
  justify-content: center
  font-weight: 400
  font-size: 12px
  &.hidden
    display: none

.range-date-picker-box
  display: flex
  align-items: center
  justify-content: center

.from-date-picker
  width: 100%
  margin-right: 6px

.to-date-picker
  width: 100%
  margin-left: 6px

th.draggable
  cursor: grab !important
  &.grabbing
    cursor: grabbing !important

th.disabled.draggable
  cursor: grab !important
  &.grabbing
    cursor: grabbing !important

.lead-right-actions-box
  display: flex
  flex-direction: row
  column-gap: 10px
  align-items: center
  justify-content: flex-end
  padding: 9.5px 0

.lead-left-box
  display: flex
  align-items: center
  height: 100%
  padding: 9.5px 0

.lead-list
  display: flex
  flex-direction: column
  row-gap: 15px
  padding: 16px
  overflow: hidden
  height: 100%
  overflow-y: scroll
  & p
    margin: 0
    &.secondary
      color: $steelGray
  & .lead-list-item
    display: flex
    flex-direction: column
    row-gap: 16px
    border-radius: 8px
    border: 1px solid $borderColor
    background-color: $lightBackgroundColor
    padding: 9px
    & .item-flex-box
      display: flex
      justify-content: space-between
      align-items: center
      & .left
        display: flex
        flex-direction: row
        column-gap: 12px
        & .products-list-box
          display: flex
          flex-direction: column
          row-gap: 8px
          & .product-item-box
            display: flex
            flex-direction: row
            column-gap: 16px
        & .commodity-box
          display: flex
          align-items: center
          font-weight: 600
          font-size: 14px
          & svg
            margin-right: 5px
      & .right
        display: flex
        flex-direction: row
        column-gap: 24px

.lead-list-item-btn
  padding: 0
  height: fit-content

.lead-widget-item-list
  display: flex
  flex-direction: column
  row-gap: 10px
  width: 100%
  & p
    margin: 0
    &.secondary
      color: $steelGray
  & .lead-widget-item
    display: flex
    flex-direction: column
    row-gap: 16px
    border: 1px solid $borderColor
    width: 100%
    border-radius: 8px
    padding: 10px
    & .lead-widget-item-flex-box
      display: flex
      justify-content: space-between
      align-items: center
      & .products-list-box
         display: flex
         flex-direction: column
         row-gap: 8px
         & .product-box
           display: flex
           flex-direction: row
           column-gap: 16px
      & .commodity-box
        display: flex
        align-items: center
        & svg
          margin-right: 5px
      & .left
        display: flex
        flex-direction: row
        column-gap: 12px
      & .right
        display: flex
        flex-direction: row
        column-gap: 24px

/* info-box for prilezitostDetail  */
.info-box
  background-color: $alternateBackgroundColor
  border: 1px solid rgba(16, 35, 74, 0.08)
  border-radius: 8px
  padding: 10px

.info-row
  display: flex
  color: $primaryFontColor
  font-weight: 400
  font-size: 14px
  gap: 15px

.info-column
  flex: 50%
  &.info-value
    font-weight: 700
    word-break: break-all
  &.highlighted
    color: $blueColor

.info-box-title
  color: $primaryFontColor
  font-weight: 700
  font-size: 13px

.info-box-note
  background-color: $alternateBackgroundColor
  border: 1px solid rgba(16, 35, 74, 0.08)
  border-radius: 8px
  padding: 10px
  display: flex
  flex-direction: column
  gap: 5px
  color: $primaryFontColor
  .title
    color: $steelGray
    font-weight: 400
    font-size: 14px

/* history logs for prilezitostDetail*/
.logs-wrapper:before
  position: absolute
  content: ' '
  top: 15px
  left: 6px
  height: calc(100% - 35px)
  border-left: 2px dashed rgba(29, 150, 238, 0.4)
  z-index: 1
.logs-wrapper.single:before
  content: none

.logs-list
  list-style: none
  padding: 0
  margin: 0

li.log-item:not(:last-child)
  margin-bottom: 10px

.log-item
  position: relative
  z-index: 2
  margin-left: 22px
  .message
    font-size: 12px
    color: $steelGray
.timestamp
  font-size: 12px
  &.date
    color: $primaryFontColor
  &.time
    color: $steelGray

.log-item:before
  position: absolute
  content: ' '
  top: 15px
  left: -21px
  width: 12px
  height: 12px
  border-radius: 100%
  background: $alternateBackgroundColor
  z-index: 2
  border: 2px solid $blueColor

.document-wrapper
  background-color: $lightBackgroundColor
  border: 1px solid rgba(16, 35, 74, 0.08)
  border-radius: 5px
  padding-block: 5px
  padding-inline: 10px


.document
  cursor: pointer
  display: inline-block
  font-size: 12px
  text-decoration: none
  padding: 5px 0
  padding-left: 39px
  background: no-repeat left center
  background-size: 24px auto
  background-image: url(../resources/img/icons/file-new.svg)

.document-name
  max-width: 100%
  overflow-wrap: break-word

.settings-btns-wrapper
  padding: 5px
  background-color: $alternateBackgroundColor
  border: 1px solid rgba(16, 35, 74, 0.08)
  border-radius: 8px
  .setting-btn
    background: $alternateBackgroundColor
    color: $primaryFontColor
    font-weight: 400
    border: none
    &:hover
      background: $alternateBackgroundColor
      border: 1px solid rgba(16, 35, 74, 0.08)

    &.selected
      font-weight: 700
      background-color: #FFFFFF
      border: 1px solid rgba(16, 35, 74, 0.08)

.table-action-button
  background: transparent !important
  color: $blueColor !important
  border: none !important
  width: 16px

.lead-edit-atcion-btn-box
  display: flex
  align-items: center
  margin-top: 10px
  & .continue-btn
    margin-left: 10px

.small-action-icon-btn
  padding: 3px !important
  border-color: $borderColor !important
  & .icon
    width: 24px

.date-range-picker input
  padding: 9px !important

.select-wrapper div
  border-radius: 8px !important
